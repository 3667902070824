import React, { useEffect, useState } from 'react'

import { Box, TextField, Autocomplete } from '@mui/material'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'

import Loading from '../../../components/Loading'
import { useStateContext } from '../../../context/ContextProvider'
import axios from '../../../lib/axios'
import { useAuth } from "../../../hooks/auth";
import { handleAxiosError, isValid } from '../../../helpers/helper'
import { t } from 'i18next'
import serviceProviderUserAPI from '../../../services/api/ServiceProviderUsers'
import customerAPI from "../../../services/api/Customers/customersAPI"
import vendorAPI from "../../../services/api/Vendors/vendorAPI"
import { AUTOCOMPLETE_STYLE, TEXTFIELD_STYLE } from "../../../constants/constants"
import style from '../../../styles/style'

const UpdateServiceProviderUser = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const { config, pusher } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [serviceProviderId, setServiceProviderId] = useState('')
    const [customer, setCustomer] = useState(null)
    const [customerInputValue, setCustomerInputValue] = useState('')
    const [vendor, setVendor] = useState(null)
    const [vendorInputValue, setVendorInputValue] = useState('')
    const [site, setSite] = useState(null)
    const [siteInputValue, setSiteInputValue] = useState('')

    const [customers, setCustomers] = useState([])
    const [vendors, setVendors] = useState([])
    const [sites, setSites] = useState([])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        if(isValid(id)) {
            getServiceProviderUser(id)
        }
    }, [id])

    useEffect(() => {
        if(isValid(companyId)) {
            getCustomers(companyId)
            getVendors(companyId)
            getSites(companyId)
        }
    }, [companyId])

    const getSites = (companyId) => {
        setIsLoading(true)
        axios
            .get(`/api/sites?company_id=${companyId}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (companyId) => {
        setIsLoading(true)
        await customerAPI.getCustomers(
            config,
            {
                where: {
                    company_id: companyId
                }
            }
        )
            .then((data) => {
                setCustomers(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async (companyId) => {
        setIsLoading(true)
        await vendorAPI.getVendors(
            config,
            {
                where: {
                    company_id: companyId
                }
            }
        )
            .then((data) => {
                setVendors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getServiceProviderUser = async (id) => {
        setIsLoading(true)

        await serviceProviderUserAPI.getServiceProviderUser(
            config,
            id,
            {
                with: [
                    'site'
                ]
            }
        )
            .then((data) => {
                if(!data) {
                    Swal
                        .fire({
                            icon: 'error',
                            title: "Service Provider User Not Found",
                            text: "The service provider user could not be found. Dismiss to be redirected to the last page.",
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: "Dismiss",
                            iconColor: '#FF0000',
                            customClass: 'error'
                        })
                        .then((result) => {
                            if(result.dismiss) {
                                setTimeout(() => {
                                    navigate(-1)
                                }, 500);
                            }
                        })
                } else {
                    setServiceProviderId(data?.service_provider_id)
                    setCompanyId(data?.company_id)
                    setName(data?.name)
                    setEmail(data?.email)
                    setCustomer({
                        id: data?.customer_id,
                        name: data?.customer_name
                    })
                    setVendor({
                        id: data?.vendor_id,
                        name: data?.vendor_name
                    })
                    setSite({
                        id: data?.site?.id,
                        name: data?.site?.name
                    })
                }

            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateServiceProviderUser = async (e) => {
        e.preventDefault();

        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['customer_id'] = customer?.id
        formData['vendor_id'] = vendor?.id
        formData['site_id'] = site?.id

        await axios
            .put(`/api/service-provider-users/${id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })

            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline'>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <button onClick={() => navigate(`/service-providers/${serviceProviderId}?tab=users`)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                        <p className='text-xl roboto font-semibold text-white'>{t('update_x', {x: t('service_provider_user')})} - {name}</p>
                    </div>
                    <button type="button" onClick={updateServiceProviderUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                        {t('update')}
                    </button>
                </div>

                <div className='w-full bg-white h-full p-5'>
                    <form className='flex justify-center flex-col items-start mt-2'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <TextField
                                sx={TEXTFIELD_STYLE}
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                            <TextField
                                sx={TEXTFIELD_STYLE}
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('site')} *</p>
                            <Autocomplete
                                disablePortal
                                id="site-autocomplete"
                                options={sites}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={site}
                                onChange={(a, b) => setSite(b) }
                                inputValue={siteInputValue}
                                onInputChange={(e, value) => setSiteInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                            <Autocomplete
                                disablePortal
                                id="customer-autocomplete"
                                options={customers}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={customer}
                                onChange={(a, b) => setCustomer(b) }
                                inputValue={customerInputValue}
                                onInputChange={(e, value) => setCustomerInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')} *</p>
                            <Autocomplete
                                disablePortal
                                id="vendor-autocomplete"
                                options={vendors}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={vendor}
                                onChange={(a, b) => setVendor(b) }
                                inputValue={vendorInputValue}
                                onInputChange={(e, value) => setVendorInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                    </form>
                </div>
            </Box>
        </>
    )
}

export default UpdateServiceProviderUser
