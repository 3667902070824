import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next"
import { ShowDate } from '../DailyBoardTableSales'
import AddButton from '../AddButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TablePagination from "@mui/material/TablePagination";
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';
import { useStateContext } from '../../context/ContextProvider'
import Swal from 'sweetalert2'
import styles from './Assigned.module.css'
import axios from '../../lib/axios'
import { useNavigate } from "react-router-dom";
import { Badge, Tooltip } from '@mui/material'
import { extractErrorDescription } from '../../helpers/helper'

function Row(props) {
    const { t } = useTranslation()

    const { row, user } = props;
    const [open, setOpen] = React.useState(false)
    const [highlight, setHighlight] = React.useState(false)
    const { config } = useStateContext()

    const highlightRowHandler = (highlight = false) => {
        setHighlight(highlight)
    }

    const clickAcceptShipmentButtonHandler = () => {
        setHighlight(true)
    }

    const clickProceedShipmentButtonHandler = () => {
        // Do some extra stuff maybe
    }

    return (
        <React.Fragment >
            <TableRow selected={highlight} sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell sx={{ padding: '8px 16px', position: 'relative' }}>
                    {
                        (
                            row.so_status === 'assigned_to_driver'
                            || row.so_status === 'loaded'
                        ) ? (
                            <AcceptShipmentButton onClick={clickAcceptShipmentButtonHandler} highlightRow={highlightRowHandler} id={row.id} config={config} />
                        ) : row.so_status === 'in_transit' ? (
                            <ProceedShipmentButton onClick={clickProceedShipmentButtonHandler} id={row.id} config={config} />
                        ) : (
                            <ShipmentNotLoadedButton />
                        )
                    }
                    {
                        row.type == 'BROKERED' ?
                            <Tooltip disableInteractive title={t('brokered')}>
                                <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(255,25,25) transparent transparent rgb(255,25,25)` }}></div>
                            </Tooltip>
                            :
                            null
                    }
                </TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.so_number}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.drop_trailer_no}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.customer_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.address}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.city}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.state}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{<ShowDate date={row.requested_delivery_date} />}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>{row.so_status_name}</TableCell>
                <TableCell sx={{ padding: '8px 16px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ padding: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow sx={{fontSize: "0.8rem"}}>
                                        <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('item_no')}</TableCell>
                                        <TableCell sx={{ width: '25%', fontSize: "inherit" }}>{t('description')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_ordered')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_to_ship')}</TableCell>
                                        <TableCell sx={{ width: '20%', fontSize: "inherit" }}>{t('qty_shipped')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.sales_order_items?.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.item_no}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.description}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{historyRow.qty_ordered}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{user?.role === 'service_provider' ? 0 : historyRow?.qty_to_ship}</TableCell>
                                            <TableCell sx={{ padding: '8px 16px' }}>{user?.role === 'service_provider' ? 0 : historyRow?.qty_shipped}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const AcceptShipmentButton = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const clickAcceptShipmentButtonHandler = () => {
        props.onClick()

        Swal.fire({
            text: t('accept_load'),
            icon: 'warning',
            customClass: 'warning2',
            iconColor: '#FEB806',
            reverseButtons: true,
            showDenyButton: true,
            showCloseButton: true,
            confirmButtonText: t('accept'),
            denyButtonText: t('negatory'),

        }).then((result) => {
            if(result.isConfirmed) {
                props.highlightRow()
                acceptShipmentHandler(props.id, props.config);
            } else if(result.isDenied || result.isDismissed) {
                props.highlightRow()
            }
        })
    }

    const acceptShipmentHandler = async (id, config) => {
        await axios.put(`/api/driver/accept-shipment/${id}`, config)
            .then(({ data }) => {
                /* Swal.fire({
                    text: data.success.message,
                    icon: "success"
                }) */
                navigate("/shipments/" + props.id)
            }).catch(({ response }) => {
                Swal.fire({
                    text: extractErrorDescription(response),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            })
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('accept_shipment')} onClick={clickAcceptShipmentButtonHandler}>
            <span style={{ transform: 'rotate(45deg)' }} className="flex items-center justify-center">
                <i className="fa-solid fa-arrow-up-long" style={{color: "#336195"}}></i>
            </span>
        </AddButton>
    )
}

const ProceedShipmentButton = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const clickProceedShipmentButtonHandler = () => {
        props.onClick()

        navigate("/shipments/" + props.id)
    }

    return (
        <AddButton hasTooltip={true} tooltipTitle={t('proceed_shipment')} onClick={clickProceedShipmentButtonHandler}>
            <span style={{ transform: 'rotate(45deg)' }} className="flex items-center justify-center">
                <i className="fa-solid fa-arrow-up-long" style={{color: "#336195"}}></i>
            </span>
        </AddButton>
    )
}

const ShipmentNotLoadedButton = () => {
    const { t } = useTranslation()

    return (
        <Tooltip title={t('shipment_not_loaded_warning')} placement='right'>
            <Badge
                badgeContent={<i className="fa-solid fa-triangle-exclamation fa-lg" style={{color: "#db1c24"}}></i>}
                sx={{'& .MuiBadge-badge': {
                    top: '7px !important'
                }}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <AddButton disabled={true}>
                    <span style={{ transform: 'rotate(45deg)' }} className="flex items-center justify-center">
                        <i className="fa-solid fa-arrow-up-long" style={{color: "#336195"}}></i>
                    </span>
                </AddButton>
            </Badge>
        </Tooltip>
    )
}

export default function CollapsibleTable({ shipments, user = null }) {
    const { t } = useTranslation()

    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);


    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    return (
        <>
            <div style={{ width: '100%', mb: 2, border: 'none' }}>
                <TableContainer>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow sx={{fontSize: "0.8rem"}}>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }}/>
                                <TableCell sx={{ width: '9.5%', fontSize: "inherit" }}>{t('order_no')}</TableCell>
                                <TableCell sx={{ width: '9.5%', fontSize: "inherit" }}>{t('trailer_no')}</TableCell>
                                <TableCell sx={{ width: '12%', fontSize: "inherit" }}>{t('customer')}</TableCell>
                                <TableCell sx={{ width: '15%', fontSize: "inherit" }}>{t('address')}</TableCell>
                                <TableCell sx={{ width: '12%', fontSize: "inherit" }}>{t('city')}</TableCell>
                                <TableCell sx={{ width: '6%', fontSize: "inherit" }}>{t('state')}</TableCell>
                                <TableCell sx={{ width: '14%', fontSize: "inherit" }}>{t('requested_delivery_date')}</TableCell>
                                <TableCell sx={{ width: '12%', fontSize: "inherit" }}>{t('status')}</TableCell>
                                <TableCell sx={{ width: '5%', fontSize: "inherit" }}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shipments?.length <= 0 ? <TableRow><TableCell colSpan={10} sx={{ textAlign: 'center' }}>{t('no_data')}</TableCell></TableRow> : null}
                            {shipments?.slice(pg * rpg, pg *
                                rpg + rpg)?.map((row) => (
                                    <Row key={row.id} row={row} user={user} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='table-pagination'
                    labelRowsPerPage={t('rowsPerPage')}
                    rowsPerPageOptions={[10, 15]}
                    component="div"
                    count={shipments.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}
