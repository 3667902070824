import React, { useState, useEffect } from 'react'

import {
    Tooltip,
    InputLabel,
    IconButton,
    MenuItem,
    FormControl,
    Select
} from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { useDebounce } from "use-debounce"

import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading'
import ServerTable from '../../components/ServerTable';
import { handleAxiosError } from '../../helpers/helper';
import CreateServiceProvider from './create/CreateServiceProvider';
import serviceProviderAPI from "../../services/api/ServiceProviders/serviceProviderAPI"

const ServiceProviders = () => {
    const { t } = useTranslation()
    const { companies, company_id, pusher, config } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [isLoading, setIsLoading] = useState(false)
    const [openCreateServiceProviderModal, setOpenCreateServiceProviderModal] = useState(false);
    const [serviceProviders, setServiceProviders] = useState([])

    const [search, setSearch] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_name || ""
        } else {
            return ""
        }
    })
    const [debouncedSearch] = useDebounce(search, 500)
    const [searchCompany, setSearchCompany] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.user_company || ""
        } else {
            return ""
        }
    })

    const [random, setRandom] = useState(null)

    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [model, setModel] = useState({
        actions: true,
        id: true,
        name: true,
        company_name: true,
    })

    useEffect(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            setSearch(filter[0]?.user_name || '')
            setSearchCompany(filter[0]?.user_company || '')
        }
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=service_providers_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        const channelUserDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovider-deleted`)
        const channelUserCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovider-created`)
        const channelUserUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovider-updated`)

        channelUserDelete.bind(`${localStorage.getItem('client_id')}-serviceprovider-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelUserCreate.bind(`${localStorage.getItem('client_id')}-serviceprovider-created-event`, data => {
            setRandom(Math.random())
        })

        channelUserUpdate.bind(`${localStorage.getItem('client_id')}-serviceprovider-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovider-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovider-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovider-updated`)
        })
    }, [])

    const getServiceProviders = async (name, company, page, pageSize) => {
        setIsLoading(true)

        await serviceProviderAPI.getServiceProviders(
            config,
            {
                where: {
                    name: name,
                    company_id: company,
                    page: page + 1,
                    page_size: pageSize
                },
                with: [
                    'company'
                ]
            }
        )
            .then((data) => {
                setServiceProviders(data?.data ?? [])
                setTotal(data?.meta?.total ?? 0)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getServiceProviders(debouncedSearch, searchCompany, page, pageSize)
    }, [debouncedSearch, searchCompany, page, pageSize, random])


    /* methods */
    const handleOpen = () => setOpenCreateServiceProviderModal(true);
    const handleClose = () => setOpenCreateServiceProviderModal(false);
    const handleChangeCompany = (event) => {
        setSearchCompany(event.target.value);
    }

    const handleClearCompanyClick = () => {
        setSearchCompany('')
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            minWidth: 210
        },
        {
            field: 'company',
            headerName: t('company'),
            flex: 1,
            minWidth: 210,
            valueGetter: (value, row) => `${value?.value?.name || ''}`,
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            cellClassName: 'padding-0',
            renderCell: (params) => <RenderDetailsButton params={params} setIsLoading={setIsLoading} />,
            width: 140
        },
    ];

    const createFilterArray = (userName, userCompany) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].user_name = userName
        filters[0].user_company = userCompany

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(search, searchCompany)
    }, [search, searchCompany])

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!openCreateServiceProviderModal}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between p-5 items-center border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('x_list', {x: t('service_provider')})}</p>
                            <Tooltip disableInteractive title={t('create_user')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between items-end w-full border-b pb-5'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" placeholder={t('search_by_name')} value={search} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearch(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            {
                                user?.role === 'master_admin' && (
                                    <div className='px-5 pt-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_company')}</InputLabel>
                                            <Select
                                                value={searchCompany}
                                                onChange={handleChangeCompany}
                                                label="Search company"
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: searchCompany ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchCompany ? (<IconButton sx={{ visibility: searchCompany ? "visible" : "hidden", padding: '0' }} onClick={handleClearCompanyClick}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                )
                            }
                        </div>
                        <div className='pt-3 w-full'>
                            <ServerTable columns={columns} items={serviceProviders} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='service_providers' />
                        </div>
                    </div>
                </div>
            </AppLayout>
            <CreateServiceProvider
                open={openCreateServiceProviderModal}
                handleClose={handleClose}
                setIsLoading={setIsLoading}
                config={config}
                companyId={company_id}
            />
        </>
    )

}

export default ServiceProviders


const RenderDetailsButton = ({ params }) => {
    const { t } = useTranslation()
    const { config } = useStateContext()

    const deleteServiceProvider = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_user') + params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios
            .delete(`/api/service-providers/${id}`, config)
            .then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/service-providers/${params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-pencil"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteServiceProvider(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
