import React, {useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Box, Button, FormControl, MenuItem, Select, Tab, TextField, Tooltip, Stack} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import {isNil, toNumber, includes, isEmpty, has, round} from 'lodash'
import update from 'immutability-helper'

import { useAuth } from '../../../hooks/auth'
import { useStateContext } from '../../../context/ContextProvider'
import axios from '../../../lib/axios'
import TableShared from '../../../components/TableShared'
import Loading from '../../../components/Loading'
import CommentHistoryModal from '../../../components/CommentHistoryModal'
import CommentHistoryButton from '../../../components/CommentHistoryButton'
import { handleAxiosError, isValid, handleZero, deleteZero, createRegexFromString } from '../../../helpers/helper'
import { MOBILE_DATE_PICKER_STYLE, MOBILE_TIME_PICKER_STYLE, SELECT_STYLE, TEXTFIELD_STYLE } from '../../../constants/constants'
import BrokerPurchaseOrderButtons from '../../../components/order_buttons/BrokerPurchaseOrderButtons'

const UpdateBrokerPurchaseOrder = () => {

    const { t } = useTranslation()
    const { config, configFile, currencySymbol, pusher } = useStateContext()
    const params = useParams()
    const { user } = useAuth({ middleware: 'guest' })
    const navigate = useNavigate()
    const location = useLocation()

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const disabled = false
    const disabledFields = true
    /* state */
    const [immutable, setImmutable] = useState(false)
    const [blocked, setBlocked] = useState(false)

    const [salesOrder, setSalesOrder] = useState({})
    const [purchaseOrder, setPurchaseOrder] = useState({})
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [value, setValue] = useState("1")
    const [driverDocs, setDriverDocs] = useState([])
    const [hasDocs, setHasDocs] = useState(false)
    const [uploadedBillOfLadingDoc, setUploadedBillOfLadingDoc] = useState({})
    const [uploadedUnloadSheetDoc, setUploadedUnloadSheetDoc] = useState({})
    const [logisticsTypeKey, setLogisticsTypeKey] = useState('')
    const [deliveryTime, setDeliveryTime] = useState('')
    const [brokerTransactionStatusSlug, setBrokerTransactionStatusSlug] = useState('')
    const [driverBolDoc, setDriverBolDoc] = useState(null)
    const [totalQtyUnloaded, setTotalQtyUnloaded] = useState(0)

    const [openCommentHistory, setOpenCommentHistory] = useState(false)

    /* form */
    const [vendorId, setVendorId] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [purchaseAddressId, setPurchaseAddressId] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')
    const [requestedCollectionDate, setRequestedCollectionDate] = useState(dayjs())
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])
    const [file, setFile] = useState('')
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs())
    const [pickupAppointmentTime, setPickupAppointmentTime] = useState(dayjs())
    const [rejectedReason, setRejectedReason] = useState('')

    useEffect(() => {
        const channelBrokerTransactionCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        const channelBrokerTransactionDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
        const channelCommentCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-broker`)
        const channelOrderDocumentUploaded = pusher.subscribe(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`)

        channelBrokerTransactionCreated.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getBrokerTransaction(data.id, true)
            }
        })
        channelBrokerTransactionDeleted.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/broker-transactions', {
                    replace: true
                })
            }
        })

        channelCommentCreated.bind(`${localStorage.getItem('client_id')}-comment-created-event`, data => {
            getComments()
        })

        channelOrderDocumentUploaded.bind(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`, data => {
            if(data.is_deleted) {
                setOrderDocuments((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setDriverDocs((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setUploadedBillOfLadingDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
                setUploadedUnloadSheetDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
            } else {
                getDocs()
                getDriverDocs()
                getUploadedBillOfLadingDoc()
                getUploadedUnloadSheetDoc()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-broker`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`)
        })
    }, [])

    useEffect(() => {
        if(isValid(vendorId)) {
            const channelPurchaseLocationCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            const channelPurchaseLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            const channelPurchaseLocationDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)

            channelPurchaseLocationCreated.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
                if(Number(data?.vendor_id) == Number(vendorId)) {
                    getPurchaseLocation(data?.id)
                }
            })
            channelPurchaseLocationUpdated.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(data?.vendor_id) == Number(vendorId)) {
                    getPurchaseLocation(vendorId)
                }
            })
            channelPurchaseLocationDeleted.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if(Number(data?.vendor_id) == Number(vendorId)) {
                    setPurchaseLocations((prev) => {
                        const item = prev.find((i) => i.id === data.id)
                        const exItem = prev.filter((i) => i.id !== item.id)
                        return exItem
                    })
                }
            })
        }

        return (() => {
            if(isValid(vendorId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            }
        })
    }, [vendorId])

    useEffect(() => {
        if(!isEmpty(purchaseOrder) && has(purchaseOrder, 'id')) {
            const channelPurchaseOrderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            const channelPurchaseOrderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            const channelOrderDocumentUploaded = pusher.subscribe(`${localStorage.getItem('client_id')}-purchase-${purchaseOrder.id}-document-event`)

            channelPurchaseOrderUpdated.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(purchaseOrder.id)) {
                    getBrokerTransaction(params.id, true)
                }
            })
            channelPurchaseOrderDeleted.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(purchaseOrder.id)) {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/purchase-orders', {
                        replace: true
                    })
                }
            })

            channelOrderDocumentUploaded.bind(`${localStorage.getItem('client_id')}-purchase-${purchaseOrder.id}-document-event`, data => {
                if(data.is_deleted) {
                    setOrderDocuments((prev) => {
                        const deletedDoc = prev.find((i) => i.id === data.id)
                        const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                        return docsUpdated
                    })
                    setDriverDocs((prev) => {
                        const deletedDoc = prev.find((i) => i.id === data.id)
                        const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                        return docsUpdated
                    })
                    setUploadedBillOfLadingDoc((prev) => {
                        return prev.id === data.id ? {} : prev
                    })
                    setUploadedUnloadSheetDoc((prev) => {
                        return prev.id === data.id ? {} : prev
                    })
                } else {
                    getDocs()
                    getDriverDocs()
                    getUploadedBillOfLadingDoc()
                    getUploadedUnloadSheetDoc()
                }
            })
        }

        return (() => {
            if(!isEmpty(purchaseOrder) && has(purchaseOrder, 'id')) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchase-${purchaseOrder.id}-document-event`)
            }
        })
    }, [purchaseOrder])

    {/* KEY EVENT */}
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* documents */
    const [orderDocuments, setOrderDocuments] = useState([])

    useEffect(() => {
        getBrokerTransaction(params.id)
    }, [params.id])

    const getBrokerTransaction = async (id, dueToPusherEvent = false) => {
        setIsLoading(true)

        await axios
            .get(`/api/broker-transactions/${id}?with=salesOrder,purchaseOrder,logisticsType`, config)
            .then((res) => {
                const data = res.data

                if(!includes(['open'], data.bt_status_slug)) {
                    setBrokerTransactionStatusSlug(data.bt_status_slug)
                    setSalesOrder(data.sales_order)
                    setPurchaseOrder(data.purchase_order)

                    setVendorId(data?.vendor.id)
                    setVendorName(data?.vendor.name)
                    setLogisticsTypeKey(data?.logistics_type.key)
                    setDeliveryTime(data?.delivery_time)
                    setRequestedCollectionDate(dayjs(data?.requested_delivery_date))
                    setRequestedDeliveryTime(dayjs(data?.requested_delivery_time))
                    setPickupAppointmentTime(dayjs(data?.pickup_appointment_time))

                    if (!dueToPusherEvent) {
                        setComments('')
                    }
                } else {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if(!isEmpty(salesOrder)) {
            setPurchaseOrderItems(salesOrder?.items.map((item) => ({...item, qty_unloaded: item?.qty_loaded || 0})))
        }
    }, [salesOrder])

    useEffect(() => {
        if(!isEmpty(purchaseOrder)) {
            setDropTrailerNo(purchaseOrder?.drop_trailer_no)
            setPickupTrailerNo(purchaseOrder?.pickup_trailer_no)
            setRejectedReason(purchaseOrder?.rejected_data.rejected_reason)
            setCommentHistory(purchaseOrder.comment_history)
            setDriverBolDoc(purchaseOrder?.uploaded_driver_bol)

            /* setPurchaseOrderItems(purchaseOrder?.items) */

            getDocs()
            getDriverDocs()
            getUploadedBillOfLadingDoc()
            getUploadedUnloadSheetDoc()
        }
    }, [purchaseOrder])

    useEffect(() => {
        loadPurchaseLocation()
    }, [purchaseLocations])

    useEffect(() => {
        if(isValid(vendorId)) {
            getPurchaseLocations(vendorId)
        }
    }, [vendorId])

    useEffect(() => {
        handleTotalQtyUnloaded()
    }, [purchaseOrderItems, params])

    useEffect(() => {
        const items = purchaseOrderItems.filter((item) => item.qty_ordered !== "")

        if (items) {
            const sum = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            if (sum > 0) {
                setBlocked(false)
            }
            else {
                setBlocked(true)
            }
        }
    }, [purchaseOrderItems])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [totalQtyUnloaded])

    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])

    useEffect(() => {
        if(['invoiced', 'completed', 'confirmed'].includes(brokerTransactionStatusSlug)) {
            setImmutable(true)
        } else {
            setImmutable(false)
        }
    }, [brokerTransactionStatusSlug])

    /* Handlers */

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleTotalPrice = () => {
        let sum = 0
        purchaseOrderItems.forEach(item => {
            sum += item.qty_unloaded * item.unit_price
        });
        setTotalPrice(sum)
        return
    }

    const handleTotalQty = () => {
        const items = purchaseOrderItems?.filter((item) => item.qty_ordered !== "")
        if (items) {
            const sum = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_unloaded), 0);
            setTotalQty(sum)
            return
        } else {
            setTotalQty(0)
        }
    }

    const handleTotalQtyUnloaded = () => {
        const items = purchaseOrderItems?.filter((item) => item.qty_ordered !== "")
        if(items) {
            const sumUnloaded = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_unloaded), 0);
            setTotalQtyUnloaded(sumUnloaded)
        } else {
            setTotalQtyUnloaded(0)
        }
    }

    const loadPurchaseLocation = () => {
        const purchaseLocation = purchaseLocations?.find(target => target.id === purchaseAddressId)
        setAddress(purchaseLocation?.address)
        setZip(purchaseLocation?.zip)
        setCity(purchaseLocation?.city)
        setState(purchaseLocation?.state)
        setPickupInstructions(purchaseLocation?.pickup_instructions)
    }

    /* API */

    const getDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=order_document&uploaded_by=${user?.id}`, config)
            .then(res => {
                const files = res.data
                setOrderDocuments(files)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDriverDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=drivers_document`, config)
            .then(res => {
                const files = res.data
                setDriverDocs(files)
                if (files.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedBillOfLadingDoc = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=proof_of_delivery`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedBillOfLadingDoc({})
                } else {
                    setUploadedBillOfLadingDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedUnloadSheetDoc = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${purchaseOrder.id}&order_type=purchase&document_type=unload_sheet`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedUnloadSheetDoc({})
                } else {
                    setUploadedUnloadSheetDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDocs = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_docs') + name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        setIsLoading(true)
        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type, ref) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);

        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', e.target.files[0])
        if(includes(['order_document', 'proof_of_delivery'], type)) {
            formData.append('order_id', params.id)
            formData.append('order_type', 'broker')
        } else {
            formData.append('order_id', purchaseOrder.id)
            formData.append('order_type', 'purchase')
        }
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                ref.current.value = ""
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const leaveComment = async () => {
        setIsLoading(true)

        const formData = new FormData()

        formData.append('order_id', params.id)
        formData.append('order_type', 'broker')
        formData.append('content', comments)

        await axios
            .post(`/api/comments`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const confirmBrokerTransaction = async () => {
        setIsLoading(true)

        const formData = {}
        formData['purchase_order_items'] = purchaseOrderItems

        await axios
            .put(`/api/broker-transactions/${params.id}/confirm`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success?.message
                }).then((result) => {
                    navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getComments = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/comments?order_id=${params.id}&order_type=broker`)
            .then(res => {
                const data = res.data
                setCommentHistory(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses/${id}`, config)
            .then((res) => {
                const data = res.data
                setPurchaseLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, data]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: data } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${id}`, config)
            .then(res => {
                const data = res.data
                setPurchaseLocations(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
        setPurchaseAddressId(purchaseOrder.purchase_address_id)
    }

    const handleUpdateQtyUnloaded = (itemId, quantity) => {
        const purchaseOrdersItemCopy = [...purchaseOrderItems]
        const item = purchaseOrdersItemCopy.find(item => item.id === itemId)
        item.qty_unloaded = toNumber(quantity)
        item.total_cost = round(toNumber(quantity) * toNumber(item.unit_price), 2)
        setPurchaseOrderItems(purchaseOrdersItemCopy)
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedCell params={params} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceiveCell params={params} />
        },
        {
            field: 'qty_unloaded',
            headerName: t('qty_unloaded'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyUnloadedCell status={brokerTransactionStatusSlug} params={params} updateQtyHandler={handleUpdateQtyUnloaded} t={t} />
        },
        {
            field: 'qty_shipped',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedCell params={params} />
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <div className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span>
                            <p>{t('view_po')} - {purchaseOrder.po_number}</p>
                        </div>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={() => navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        {/* Status */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('status')}</p>
                            <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''} capitalize`}>
                                {t(brokerTransactionStatusSlug)}
                            </div>
                        </div>
                        {/* Rejection Reasoning */}
                        {purchaseOrder?.rejected ? (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('reason_for_rejection')}</p>
                                <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{t(`${rejectedReason}`)}</div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Vendor */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    type="text"
                                    disabled
                                    className='w-full'
                                    name="vendor_name"
                                    value={vendorName}
                                />
                            </div>
                        </div>

                        {/* Purchase Location */}

                        <div className='w-full pb-4'>
                            <Stack
                                direction='row'
                                gap={1}
                                alignItems='baseline'
                                justifyContent='space-between'
                            >
                                <p className="text-[14px] text-[#718096]">{t('purchase_location')} *</p>
                                {(purchaseAddressId && isValid(pickupInstructions)) ? (
                                    <Tooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                {pickupInstructions}
                                            </div>
                                        }
                                    >
                                        <Stack
                                            direction='row'
                                            gap={1}
                                            alignItems='center'
                                            className='text-[#718096]'
                                        >
                                            <MenuBookIcon fontSize='18px'/>
                                            <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('pickup_instructions')}</span>
                                        </Stack>
                                    </Tooltip>
                                ) : null}
                            </Stack>

                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select
                                        sx={SELECT_STYLE}
                                        value={purchaseAddressId}
                                        disabled
                                    >
                                        {purchaseLocations?.map((item, index) => <MenuItem key={`plid-${item.id}_${index}`} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {/* Address */}
                        {
                            purchaseAddressId ?
                                <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                    <div className='w-full'>{address}</div>
                                    {purchaseAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                </div>
                                :
                                null
                        }

                        {/* Drop Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    type="text"
                                    disabled={disabledFields}
                                    className='w-full'
                                    name="drop_trailer_no"
                                    value={dropTrailerNo}
                                    onChange={(e) => { setDropTrailerNo(e.target.value) }}
                                />
                            </div>
                        </div>

                        {/* Pickup Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    type="text"
                                    disabled={disabledFields}
                                    className='w-full'
                                    name="pickup_trailer_no"
                                    value={pickupTrailerNo}
                                    onChange={(e) => { setPickupTrailerNo(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='lg:flex gap-3 w-full'>
                            {/* Requested Collection Date */}
                            <div className={`w-full ${(deliveryTime == 'appointment') ? 'lg:w-1/2' : ''} pb-4`}>
                                <p className="text-[14px] text-[#718096]">{t('requested_collection_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            disabled={disabledFields}
                                            sx={MOBILE_DATE_PICKER_STYLE}
                                            format="YYYY-MM-DD"
                                            value={requestedCollectionDate}
                                            onChange={(newValue) => setRequestedCollectionDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            {(includes(['delivery', 'tpl'], logisticsTypeKey) && deliveryTime == 'appointment') && (
                                <div className={`w-full lg:w-1/2 pb-4`}>
                                    {logisticsTypeKey == 'delivery' && (
                                        <>
                                            <p className="text-[14px] text-[#718096]">{t('delivery_appointment_time')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker
                                                            disabled={disabledFields}
                                                            sx={MOBILE_TIME_PICKER_STYLE}
                                                            value={requestedDeliveryTime}
                                                            onChange={(newValue) => setRequestedDeliveryTime(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </>
                                    )}

                                    {logisticsTypeKey == 'pick_up' && (
                                        <>
                                            <p className="text-[14px] text-[#718096]">{t('pickup_appointment_time')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker
                                                            disabled={disabledFields}
                                                            sx={MOBILE_TIME_PICKER_STYLE}
                                                            value={pickupAppointmentTime}
                                                            onChange={(newValue) => setPickupAppointmentTime(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                            <div className='pb-11 pt-1 pl-5'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('purchaseOrderItems')}</p>
                            </div>
                            <div className='border-b border-t mt-4'>
                                <TableShared columns={columns} items={purchaseOrderItems} disableColumnMenu={true} />
                            </div>
                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                        <p>{t('totalPrice')}</p>
                                        <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{totalQty}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block gap-3 lg:flex'>
                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2 h-fit'>
                                <div className='pt-1 p-5 flex justify-between items-start'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={commentHistory?.length ?? ''}
                                        backgroundColor='#336195'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <div className='w-full p-5 pt-0'>
                                    <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        value={comments}
                                        onChange={e => setComments(e.target.value)}
                                        sx={TEXTFIELD_STYLE}
                                        disabled={immutable}
                                    />
                                </div>
                                <div className='pt-1 p-5 flex justify-end items-start'>
                                    <button type="button" onClick={leaveComment} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#336195] hover:bg-[#284d77] disabled:bg-[#B8B7BC]' disabled={immutable}>
                                        {t('leave_comment')}
                                    </button>
                                </div>
                            </div>

                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                </div>
                                <div className='w-full'>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="document tab list" variant='scrollable'>
                                                <Tab sx={{ textTransform: 'none' }} value="1" label={t('order_documents')} />
                                                <Tab sx={{ textTransform: 'none' }} value="2" label={t('bill_of_lading')} />
                                                <Tab sx={{ textTransform: 'none' }} value="3" label={t('unload_sheet')} />
                                            </TabList>
                                        </Box>
                                        {/* ORDER DOCUMENTS */}
                                        <TabPanel value="1">
                                            <div className='pb-5 w-full text-center'>
                                                <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }} disabled={immutable}>
                                                    <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                </Button>
                                                <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'order_document', inputRef)} />
                                            </div>
                                            <>
                                                {
                                                    orderDocuments?.map((item, index) => (

                                                        <div className='pb-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                        || item.uploaded_by === user?.id
                                                                    ) ? (
                                                                        <button className={`text-neutral-500 ${immutable || (disabledFields && !includes(['vendor_user', 'service_provider_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {hasDocs ? (
                                                    <div className='pb-5 text-sm text-slate-600'>{t('driver_documents')}</div>
                                                ) : (
                                                    ''
                                                )}
                                                {
                                                    driverDocs?.map((item, index) => (

                                                        <div className='pb-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                        || item.uploaded_by === user?.id
                                                                    ) ? (
                                                                        <button className={`text-neutral-500 ${immutable || (disabledFields && !includes(['vendor_user', 'service_provider_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)} disabled={immutable}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </TabPanel>

                                        {/* PROOFS OF DELIVERY */}
                                        <TabPanel value="2">

                                            {/* Auto-generated Bills of Lading */}

                                            {(Object.keys(uploadedBillOfLadingDoc).length <= 0 && !driverBolDoc) && (
                                                <div className='pb-5 flex justify-between'>
                                                    <p className='text-blue-900 underline'><a
                                                        href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=view&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                        target="_blank"><span className="text-[#336195]"
                                                            style={{ cursor: 'pointer' }}>{t('bill_of_lading')}</span></a>
                                                    </p>
                                                    <div className='flex items-center gap-3'>
                                                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=view&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                            target="_blank" download>
                                                            <button className='text-neutral-500'><Tooltip disableInteractive
                                                                title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                    className="fa-solid fa-eye"></i></Tooltip></button>
                                                        </a>
                                                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=download&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`}
                                                            target="_blank" download>
                                                            <button className='text-neutral-500'><Tooltip disableInteractive
                                                                title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                    className="fa-solid fa-download"></i></Tooltip></button>
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Manually-uploaded Bills of Lading */}

                                            {Object.keys(uploadedBillOfLadingDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pb-5'>{t('uploaded_x', { x: t('bill_of_lading') })}</div>

                                                    <div className='pb-5 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedBillOfLadingDoc.document_url} target="_blank" download>{uploadedBillOfLadingDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedBillOfLadingDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                    || uploadedBillOfLadingDoc.uploaded_by === user?.id
                                                                ) ? (
                                                                    <button className={`text-neutral-500 ${immutable ? 'hidden' : 'block'}`} onClick={e => deleteDocs(uploadedBillOfLadingDoc.id, uploadedBillOfLadingDoc.document_name)} disabled={immutable}><Tooltip disableInteractive title={t('delete_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='pb-5 w-full text-center'>
                                                    <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }} disabled={immutable}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('BoL') })}</p> </div>
                                                    </Button>
                                                    <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'proof_of_delivery', inputRef)} />
                                                </div>
                                            )}

                                            {driverBolDoc ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pb-5'>{t('driver_bill_of_lading')}</div>

                                                    <div className='flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3' ><a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download>{driverBolDoc?.document_name}</a></p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('download_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-download"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </TabPanel>

                                        {/* UNLOAD SHEET(s) */}
                                        <TabPanel value="3">
                                            {(Object.keys(uploadedUnloadSheetDoc).length <= 0) && (
                                                <div className='pt-5 flex justify-between'>
                                                    <p className='text-blue-900 underline' >
                                                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${purchaseOrder?.id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`} target="_blank">
                                                            <span className="text-[#336195]" style={{ cursor: 'pointer' }}>{t('unload_sheet')}</span>
                                                        </a>
                                                    </p>
                                                    <div className='flex items-center gap-2'>
                                                        <a
                                                            href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${purchaseOrder?.id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                            target="_blank"

                                                        >
                                                            <button className='text-neutral-500'>
                                                                <Tooltip disableInteractive title={t('view_x', { x: t('unload_sheet') })} placement='bottom'>
                                                                    <i className="fa-solid fa-eye"></i>
                                                                </Tooltip>
                                                            </button>
                                                        </a>

                                                        <a
                                                            href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${purchaseOrder?.id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                            target="_blank"
                                                            download
                                                        >
                                                            <button className='text-neutral-500'>
                                                                <Tooltip disableInteractive title={t('download_x', { x: t('unload_sheet') })} placement='bottom'>
                                                                    <i className="fa-solid fa-download"></i>
                                                                </Tooltip>
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            )}

                                            {Object.keys(uploadedUnloadSheetDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('uploaded_x', { x: t('unload_sheet') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedUnloadSheetDoc.document_url} target="_blank" download>{uploadedUnloadSheetDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedUnloadSheetDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('unload_sheet') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                    || uploadedUnloadSheetDoc.uploaded_by === user?.id
                                                                ) ? (
                                                                    <button className={`text-neutral-500 ${immutable || (disabledFields && !includes(['vendor_user', 'service_provider_user'], user?.role)) ? 'hidden' : 'block'}`} onClick={e => deleteDocs(uploadedUnloadSheetDoc.id, uploadedUnloadSheetDoc.document_name)} disabled={immutable}><Tooltip disableInteractive title={t('delete_x', { x: t('unload_sheet') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='pt-5 w-full text-center'>
                                                    <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }} disabled={brokerTransactionStatusSlug !== 'on_site' && brokerTransactionStatusSlug !== 'completed_by_driver'}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('unload_sheet') })}</p> </div>
                                                    </Button>
                                                    <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'unload_sheet', inputRef)} />
                                                </div>
                                            )}
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-3'>
                            {
                                (
                                    !purchaseOrder?.rejected
                                    && purchaseOrder?.type == 'BROKER'
                                ) ? (
                                    <BrokerPurchaseOrderButtons
                                        statusSlug={brokerTransactionStatusSlug}
                                        confirmHandler={confirmBrokerTransaction}
                                        immutable={immutable}
                                        disabled={disabled}
                                        blocked={blocked}
                                        user={user}
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </Box>
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default UpdateBrokerPurchaseOrder

const QtyOrderedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            disabled
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty"
            value={qty}
        />
    )

}

const QtyToReceiveCell = ({ params }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_receive])

    return (
        <TextField
            type="text"
            disabled
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-receive"
            value={qty}
        />
    )

}

const QtyUnloadedCell = ({ params, status, updateQtyHandler, t }) => {
    const disabled = status !== 'on_site' && status !== 'completed_by_driver'
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_unloaded])

    const handleOnChange = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{${0},${5}})$/i`)
        if (regex.test(e.target.value)) {
            if(e.target.value > Number(params.row?.qty_ordered)) {
                Swal.fire({
                    text: t('x_cannot_be_greater_than_y', {
                        x: t('qty_unloaded'),
                        y: Number(params.row?.qty_ordered)
                    }),
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
                setQty(Number(params.row?.qty_ordered))
                updateQtyHandler(
                    params.row.id,
                    Number(params.row?.qty_ordered)
                )
                return
            }

            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
                return
            }

            setQty(e.target.value)
            updateQtyHandler(
                params.row.id,
                e.target.value
            )
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled}
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-unloaded"
            value={qty}
            onChange={(e) => handleOnChange(e)}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyReceivedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_received])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received"
            value={qty}
            disabled
        />
    )
}

const VendorCountCell = ({ params }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.vendor_count])

    return (
        <TextField
            type="text"
            variant='standard'
            disabled
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count"
            value={qty}
        />
    )
}

const UnitPriceCell = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {
    const [total, setTotal] = useState(0)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.qty_unloaded * params.params.row.unit_price)
    }, [params])

    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}
