import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { Tooltip} from '@mui/material'
import Swal from 'sweetalert2'
import {Link, useSearchParams} from 'react-router-dom'

import TableShared from '../../components/TableShared'
import AddButton from '../../components/AddButton'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import {useAuth} from "../../hooks/auth"
import {handleAxiosError, isValid} from '../../helpers/helper'
import { includes } from 'lodash'
import serviceProviderUsersAPI from '../../services/api/ServiceProviderUsers'
import CreateServiceProviderUser from './create/CreateServiceProviderUser'
import { t } from 'i18next'

const ServiceProviderUsers = ({
    setIsLoading,
    serviceProviderId,
    companyId,
    customers = [],
    vendors = [],
    sites = [],
    pusher,
    config
}) => {
    const [searchName, setSearchName] = useState('')
    const [serviceProviderUsers, setServiceProviderUsers] = useState([])
    const [openCreateServiceProviderUserModal, setOpenCreateServiceProviderUserModal] = useState(false)

    useEffect(() => {
        if(isValid(serviceProviderId)) {
            getServiceProviderUsers(serviceProviderId)
        }
    }, [serviceProviderId])

    useEffect(() => {
        if(pusher) {
            const channelServiceProviderUserDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-deleted`)
            const channelServiceProviderUserUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-updated`)
            const channelServiceProviderUserCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-created`)

            channelServiceProviderUserDeleted.bind(`${localStorage.getItem('client_id')}-serviceprovideruser-deleted-event`, data => {
                if(Number(serviceProviderId) === Number(data.service_provider_id)) {
                    getServiceProviderUsers(serviceProviderId)
                }
            })

            channelServiceProviderUserUpdated.bind(`${localStorage.getItem('client_id')}-serviceprovideruser-updated-event`, data => {
                if(Number(serviceProviderId) === Number(data.service_provider_id)) {
                    getServiceProviderUsers(serviceProviderId)
                }
            })

            channelServiceProviderUserCreated.bind(`${localStorage.getItem('client_id')}-serviceprovideruser-created-event`, data => {
                if(Number(serviceProviderId) === Number(data.service_provider_id)) {
                    getServiceProviderUsers(serviceProviderId)
                }
            })
        }

        return (() => {
            if(pusher) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovideruser-created`)
            }
        })
    }, [pusher, serviceProviderId])

    const getServiceProviderUsers = async (serviceProviderId) => {
        setIsLoading(true)
        await serviceProviderUsersAPI.getServiceProviderUsers(
            config,
            {
                where: {
                    service_provider_id: serviceProviderId
                },
                with: [
                    'site'
                ]
            }
        )
            .then((data) => setServiceProviderUsers(data))
            .finally(() => setIsLoading(false))
    }

    const columns = [
        {
            field: 'id',
            headerName: t('id'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'site',
            headerName: t('site'),
            flex: 1,
            minWidth: 210,
            valueGetter: (value, row) => `${value?.value?.name || ''}`,
        },
        {
            field: 'customer_name',
            headerName: t('customer'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'vendor_name',
            headerName: t('vendor'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => {
                return (
                    <ServiceProviderUserActions
                        params={params}
                        config={config}
                        setIsLoading={setIsLoading}
                    />
                )
            },
            minWidth: 200
        }
    ]

    const handleOpen = () => setOpenCreateServiceProviderUserModal(true)
    const handleClose = () => setOpenCreateServiceProviderUserModal(false)

    const filteredServiceProviderUsers = serviceProviderUsers?.filter(spu => {
        if (searchName === null) {
            return spu
        }

        if (spu?.name?.toLocaleLowerCase().includes(searchName?.toLocaleLowerCase())) {
            return spu
        }
    })

    return (
        <>
            <div className='p-5'>
                <div className='pb-5 bg-white mb-2 rounded-md'>
                    <div className='flex justify-between items-center p-5 border-b'>
                        <p style={{fontWeight: 600, fontSize: '16px'}}>{t('service_provider_users')}</p>
                        <Tooltip disableInteractive title={t('create_x', {x: t('service_provider_user')})} placement='bottom'>
                            <div>
                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='w-full border-b pb-5'>
                        <div className='flex justify-between items-end w-1/2'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input type="text" value={searchName} placeholder={t('search_by_name')} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchName(e.target.value)} />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-3'>
                        <TableShared items={filteredServiceProviderUsers} columns={columns} />
                    </div>
                </div>
            </div>
            <CreateServiceProviderUser
                open={openCreateServiceProviderUserModal}
                handleClose={handleClose}
                setIsLoading={setIsLoading}
                customers={customers}
                vendors={vendors}
                sites={sites}
                config={config}
                companyId={companyId}
                serviceProviderId={serviceProviderId}
            />
        </>
    )
}

export default ServiceProviderUsers

const ServiceProviderUserActions = (args) => {
    const { params, config, setIsLoading } = args
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const { user, loginImpersonate } = useAuth({ middleware: 'guest' })
    const { setCompany_id, setChoosesite } = useStateContext()
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const deleteItems = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('delete_x', {x: t('service_provider_user')}) + params.row.name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }

        setIsLoading(true)
        await axios
            .delete(`/api/service-provider-users/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const impersonate = async (id, role, company_id, site_id, name) => {
        searchParams.delete('tab')
        setSearchParams(searchParams)

        await loginImpersonate({
            id: id,
            impersonator_id: user?.id,
            impersonatee_name: name,
            impersonatee_site_id: site_id,
            impersonatee_company_id: company_id,
            role: role,
            site_id: localStorage.getItem('site'),
            company_id: localStorage.getItem('company_id'),
            setErrors,
            setStatus,
        }).then((data) => {
            setCompany_id(company_id)
            setChoosesite(localStorage.getItem('site'))
        }).catch(({ response }) => {
            setSearchParams({ tab: 'users' })
        })
    }

    return (
        <>
            <div className='flex justify-between'>
                {
                    (
                        includes(['master_admin', 'office_manager'], user?.role)
                        && (localStorage.getItem('impersonate') === null)
                        && (params.row.role_slug !== "master_admin")
                    ) ?
                        <Tooltip disableInteractive title={t('impersonate')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => impersonate(params.row.id, 'customers_user', params.row.company_id, params.row.site_id, params.row.name)}><i className="fa-solid fa-user"></i></span>
                            </div>
                        </Tooltip> : ''
                }
                <Tooltip disableInteractive title={t('change_password')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/users/change-password/${params.row.id}`}>
                            <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-key"></i></span>
                        </Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/service-provider-user/${params.row.id}`}><span style={{ cursor: 'pointer' }}
                            className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i
                                className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItems(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

