import {
    Modal,
    Box,
    TextField,
    Autocomplete,
} from "@mui/material"
import { useState, useEffect } from "react"
import Swal from "sweetalert2"
import { handleAxiosError } from "../../../helpers/helper"
import { AUTOCOMPLETE_STYLE, TEXTFIELD_STYLE } from "../../../constants/constants"
import style from '../../../styles/style'
import { t } from "i18next"
import axios from "../../../lib/axios"

const CreateServiceProviderUser = ({
    open,
    handleClose,
    setIsLoading,
    customers = [],
    vendors = [],
    sites = [],
    config,
    companyId,
    serviceProviderId
}) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [customer, setCustomer] = useState(null)
    const [customerInputValue, setCustomerInputValue] = useState('')
    const [vendor, setVendor] = useState(null)
    const [vendorInputValue, setVendorInputValue] = useState('')
    const [site, setSite] = useState(null)
    const [siteInputValue, setSiteInputValue] = useState('')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13' && open) {
                createServiceProviderUser()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name, email, customer, vendor, site]);

    const createServiceProviderUser = async () => {
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['email'] = email
        formData['customer_id'] = customer?.id
        formData['vendor_id'] = vendor?.id
        formData['company_id'] = companyId
        formData['site_id'] = site?.id
        formData['service_provider_id'] = serviceProviderId

        await axios
            .post(`/api/service-provider-users`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setName('')
                setEmail('')
                setCustomer(null)
                setVendor(null)
                setSite(null)
                handleClose()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <div className='flex justify-between items-center p-5 pr-0 pt-0 pb-0' style={{ backgroundColor: '#336195', borderRadius: '5px 5px 0 0' }}>
                    <div className='flex gap-4 items-baseline'>
                        <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                            <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                        </div>
                        <p className='text-xl roboto font-semibold text-white'>{t('create_x', {x: t('service_provider_user')})}</p>
                    </div>
                    <button type="button" onClick={createServiceProviderUser} className='text-white px-4 py-6 uppercase self-end roboto bg-zinc-900 hover:bg-zinc-700 disabled:bg-zinc-400'>
                        {t('create')}
                    </button>
                </div>

                <div className='w-full bg-white h-full p-5'>
                    <form className='flex justify-center flex-col items-start mt-2'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <TextField
                                sx={TEXTFIELD_STYLE}
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                            <TextField
                                sx={TEXTFIELD_STYLE}
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('site')} *</p>
                            <Autocomplete
                                disablePortal
                                id="site-autocomplete"
                                options={sites}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={site}
                                onChange={(a, b) => setSite(b) }
                                inputValue={siteInputValue}
                                onInputChange={(e, value) => setSiteInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                            <Autocomplete
                                disablePortal
                                id="customer-autocomplete"
                                options={customers}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={customer}
                                onChange={(a, b) => setCustomer(b) }
                                inputValue={customerInputValue}
                                onInputChange={(e, value) => setCustomerInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')} *</p>
                            <Autocomplete
                                disablePortal
                                id="vendor-autocomplete"
                                options={vendors}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={vendor}
                                onChange={(a, b) => setVendor(b) }
                                inputValue={vendorInputValue}
                                onInputChange={(e, value) => setVendorInputValue(value)}
                                renderInput={(params) => <TextField {...params} /> }
                                sx={{
                                    ...AUTOCOMPLETE_STYLE,
                                    '.MuiOutlinedInput-input': {
                                        padding: '0 !important'
                                    }
                                }}
                            />
                        </div>
                    </form>
                </div>
            </Box>
        </Modal>
    )
}

export default CreateServiceProviderUser
