import {
    Box,
    TextField,
    Tab
} from "@mui/material"
import { useState, useEffect } from "react"
import Swal from "sweetalert2"
import { handleAxiosError, isValid } from "../../../helpers/helper"
import { TEXTFIELD_STYLE } from "../../../constants/constants"
import style from '../../../styles/style'
import { t } from "i18next"
import axios from "../../../lib/axios"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { useStateContext } from "../../../context/ContextProvider"
import Loading from "../../../components/Loading"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import ServiceProviderUsers from "../ServiceProviderUsers"
import customerAPI from "../../../services/api/Customers/customersAPI"
import vendorAPI from "../../../services/api/Vendors/vendorAPI"
import serviceProviderAPI from "../../../services/api/ServiceProviders/serviceProviderAPI"

const UpdateServiceProvider = () => {
    const { pusher, config } = useStateContext()

    const { id } = useParams()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [name, setName] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [customers, setCustomers] = useState([])
    const [vendors, setVendors] = useState([])
    const [sites, setSites] = useState([])
    const [tabValue, setTabValue] = useState(() => {
        if(
            searchParams.has('tab')
            && ['general', 'users'].includes(searchParams.get('tab'))
        ) {
            return searchParams.get('tab')
        } else {
            return 'general'
        }
    })

    useEffect(() => {
        if (localStorage.getItem('showImpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('impersonation')}),
                text: t('you_are_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showImpersonationMessage')
            })
        } else if (localStorage.getItem('showDeimpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('deimpersonation')}),
                text: t('you_are_no_longer_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showDeimpersonationMessage')
                localStorage.removeItem('impersonatee_name')
            })
        }

        const channelServiceProviderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovider-deleted`)
        const channelServiceProviderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-serviceprovider-updated`)

        channelServiceProviderDeleted.bind(`${localStorage.getItem('client_id')}-serviceprovider-deleted-event`, data => {
            if(Number(data.id) === Number(id)) {
                navigate('/service-providers')
            }
        })

        channelServiceProviderUpdated.bind(`${localStorage.getItem('client_id')}-serviceprovider-updated-event`, data => {
            if(Number(data.id) === Number(id)) {
                getServiceProvider(data.id)
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovider-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-serviceprovider-updated`)
        })
    }, [])

    useEffect(() => {
        if(isValid(id)) {
            getServiceProvider(id)
        }
    }, [id])

    useEffect(() => {
        if(isValid(companyId)) {
            getCustomers(companyId)
            getVendors(companyId)
            getSites(companyId)
        }
    }, [companyId])

    useEffect(() => {
        setSearchParams({ tab: tabValue })
    }, [tabValue])

    const getSites = (companyId) => {
        setIsLoading(true)
        axios
            .get(`/api/sites?company_id=${companyId}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (companyId) => {
        setIsLoading(true)

        await customerAPI.getCustomers(
            config,
            {
                where: {
                    company_id: companyId
                }
            }
        )
            .then((data) => {
                setCustomers(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async (companyId) => {
        setIsLoading(true)

        await vendorAPI.getVendors(
            config,
            {
                where: {
                    company_id: companyId
                }
            }
        )
            .then((data) => {
                setVendors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getServiceProvider = async (serviceProviderId) => {
        setIsLoading(true)

        await serviceProviderAPI.getServiceProvider(
            config,
            serviceProviderId
        )
            .then((data) => {
                if(!data) {
                    Swal
                        .fire({
                            icon: 'error',
                            title: "Service Provider Not Found",
                            text: "The service provider could not be found. Dismiss to be redirected to the service providers page.",
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: "Dismiss",
                            iconColor: '#FF0000',
                            customClass: 'error'
                        })
                        .then((result) => {
                            if(result.dismiss) {
                                setTimeout(() => {
                                    navigate('/service-providers')
                                }, 500);
                            }
                        })
                } else {
                    setName(data.name)
                    setCompanyId(data.company?.id ?? data.company)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateServiceProvider = async () => {
        setIsLoading(true)
        const formData = {}

        formData['name'] = name
        formData['company_id'] = companyId

        await axios
            .put(`/api/service-providers/${id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <>
            {isLoading && <Loading />}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_x', {x: t('service_provider')})} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to={'/service-providers'}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <TabContext value={tabValue}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabValueChange} aria-label="service-provider-tabs" variant="scrollable">
                                <Tab sx={{textTransform: 'none'}} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition="start" />
                                <Tab sx={{textTransform: 'none'}} value="users" label={t('users')} icon={<i className="fa-solid fa-users-rays fa-lg"></i>} iconPosition="start" />
                            </TabList>
                        </Box>
                        <TabPanel value="general">
                            <form className='flex justify-center flex-col items-start mt-2'>
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel value="users" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <ServiceProviderUsers
                                setIsLoading={setIsLoading}
                                serviceProviderId={id}
                                companyId={companyId}
                                customers={customers}
                                vendors={vendors}
                                sites={sites}
                                pusher={pusher}
                                config={config}
                            />
                        </TabPanel>
                    </TabContext>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateServiceProvider} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateServiceProvider
