import React, { useState, useEffect } from 'react'

import { Tooltip } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import update from 'immutability-helper'
import { Link } from 'react-router-dom'

import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableBroker from '../../components/broker/TableBroker'
import { useAuth } from '../../hooks/auth'
import CreateBrokerTransaction from '../../components/broker/CreateBrokerTransaction'
import Navigation from '../../components/sales_purchase_orders/Navigation'
import { capitalize, includes } from 'lodash'


const BrokerTransactionsDailyBoard = () => {

    const { user } = useAuth({ middleware: 'guest' })

    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher } = useStateContext()

    const [pageToday, setPageToday] = useState(1)
    const [rowsPerPageToday, setRowsPerPageToday] = useState(10)
    const [pageFuture, setPageFuture] = useState(1)
    const [rowsPerPageFuture, setRowsPerPageFuture] = useState(10)
    const [ordersTodayTotal, setOrdersTodayTotal] = useState(0)
    const [ordersFutureTotal, setOrdersFutureTotal] = useState(0)

    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [random, setRandom] = useState(null)
    const [listOfStatuses, setListOfStatuses] = useState([])
    const [listOfStatusesFuture, setListOfStatusesFuture] = useState([])
    const [listOfDates, setListOfDates] = useState([])
    const [listOfDatesFuture, setListOfDatesFuture] = useState([])

    /* orders */
    const [orders, setOrders] = useState([])
    const [ordersFuture, setOrdersFuture] = useState([])

    /* filters */
    const [searchStatusFuture, setSearchStatusFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_bt_status || ''
        } else {
            return ''
        }
    })
    const [searchDateFuture, setSearchDateFuture] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.future_bt_requested_delivery_date || ''
        } else {
            return ''
        }
    })
    const [SearchStatus, setSearchStatus] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_status || ''
        } else {
            return ''
        }
    })
    const [searchDate, setSearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.bt_requested_delivery_date || ''
        } else {
            return ''
        }
    })

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-created-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${localStorage.getItem('site')}`, data => {

            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-brokertransaction-created-event-site-${localStorage.getItem('site')}`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [choosesite])

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
    }, [])

    useEffect(() => {
        if (choosesite) {
            getOrders()
        }
    }, [choosesite, searchDate, SearchStatus, pageToday, rowsPerPageToday, random])

    useEffect(() => {
        if (choosesite) {
            getOrdersFuture()
        }
    }, [choosesite, searchDateFuture, searchStatusFuture, pageFuture, rowsPerPageFuture, random])

    useEffect(() => {
        setPageToday(1)
    }, [searchDate, SearchStatus])

    useEffect(() => {
        setPageFuture(1)
    }, [searchDateFuture, searchStatusFuture])

    const getOrders = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/broker-transactions?site_id=${choosesite}&requested_delivery_date=${searchDate}&status_id=${SearchStatus}&active=true&today=true&page=${pageToday}&page_size=${rowsPerPageToday}`, config)
            .then(res => {
                setListOfStatuses(res.data?.available_statuses)
                setListOfDates(res.data?.available_dates)
                setOrders(res.data?.data)
                setOrdersTodayTotal(res.data?.total)
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false))
    }

    const getOrdersFuture = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/broker-transactions?site_id=${choosesite}&requested_delivery_date=${searchDateFuture}&status_id=${searchStatusFuture}&active=true&future=true&page=${pageFuture}&page_size=${rowsPerPageFuture}`, config)
            .then(res => {
                setListOfStatusesFuture(res.data?.available_statuses)
                setListOfDatesFuture(res.data?.available_dates)
                setOrdersFuture(res.data?.data)
                setOrdersFutureTotal(res.data?.total)
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false))
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleClearStatus = () => {
        setSearchStatusFuture('')
    }

    const handleClearStatus1 = () => {
        setSearchStatus('')
    }

    const handleClearDate = () => {
        setSearchDateFuture('')
    }

    const handleClearDate1 = () => {
        setSearchDate('')
    }

    const createFilterArray = (status, requestedCollectionDate, futureStatus, futureRequestedCollectionDate) => {

        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].bt_status = status
        filters[0].bt_requested_delivery_date = requestedCollectionDate
        filters[0].future_bt_status = futureStatus
        filters[0].future_bt_requested_delivery_date = futureRequestedCollectionDate

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    useEffect(() => {
        createFilterArray(SearchStatus, searchDate, searchStatusFuture, searchDateFuture)
    }, [SearchStatus, searchDate, searchStatusFuture, searchDateFuture])

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <Navigation route='broker-transactions' user={user} />
                <div className='p-6 flex w-full items-start justify-between gap-5 max-[1300px]:flex-col'>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ fontSize: '16px', color: '#148c8cb3' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-repeat"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('todays_broker_transactions')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <>
                                        <Tooltip disableInteractive title={t('create_new_broker')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus "></i></AddButton>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>

                                        <Select
                                            value={SearchStatus}
                                            onChange={e => setSearchStatus(e.target.value)}
                                            label="Search role"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: SearchStatus ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={SearchStatus ? (<IconButton sx={{ visibility: SearchStatus ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                listOfStatuses.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    listOfStatuses.map((status, index) => (
                                                        <MenuItem key={index} value={status.id}>{capitalize(t(status.key))}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>

                                        <Select
                                            value={searchDate}
                                            onChange={e => setSearchDate(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate1}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                listOfDates.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    listOfDates.map((date) => (
                                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                        <div>
                            <TableBroker
                                items={orders}
                                total={ordersTodayTotal}
                                user={user}
                                page={pageToday}
                                rowsPerPage={rowsPerPageToday}
                                opc={setPageToday}
                                orppc={setRowsPerPageToday}
                            />
                        </div>
                    </div>
                    <div className='w-full bg-white rounded-md'>
                        <div className='p-5 flex justify-between items-center border-b border-[#EEEFF2]'>
                            <div className='flex gap-2 items-center'>
                                <span style={{ fontSize: '16px', color: '#148c8cb3' }} className="flex items-center justify-center">
                                    <i className="fa-solid fa-repeat"></i>
                                </span>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('future_broker_transactions')}</p>
                            </div>
                            <div className='flex gap-5 items-center'>
                                {['master_admin', 'office_manager'].includes(user?.role) ? (
                                    <>
                                        <Tooltip disableInteractive title={t('create_new_broker')} placement='bottom'>
                                            <div>
                                                <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='p-5 border-b border-[#EEEFF2]'>
                            <div className='flex justify-between items-end w-full gap-3'>
                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>

                                        <Select
                                            value={searchStatusFuture}
                                            onChange={e => setSearchStatusFuture(e.target.value)}
                                            label="Search role"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchStatusFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchStatusFuture ? (<IconButton sx={{ visibility: searchStatusFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearStatus}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                listOfStatusesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    listOfStatusesFuture.map((status, index) => (
                                                        <MenuItem key={index} value={status.id}>{capitalize(t(status.key))}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full'>
                                    <FormControl sx={{ width: 'inherit' }}>
                                        <InputLabel sx={{ fontSize: '12px', lineHeight: 2 }} id="demo-simple-select-standard-label">{t('search_by_request_delivery_date')}</InputLabel>
                                        <Select
                                            value={searchDateFuture}
                                            onChange={e => setSearchDateFuture(e.target.value)}
                                            label="Search Past Or Present Date"
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: searchDateFuture ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={searchDateFuture ? (<IconButton sx={{ visibility: searchDateFuture ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                listOfDatesFuture.length < 1 ?
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                    :
                                                    listOfDatesFuture.map((date) => (
                                                        <MenuItem key={date} value={date}>{date}</MenuItem>
                                                    ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                        <div>
                            <TableBroker
                                items={ordersFuture}
                                total={ordersFutureTotal}
                                user={user}
                                page={pageFuture}
                                rowsPerPage={rowsPerPageFuture}
                                opc={setPageFuture}
                                orppc={setRowsPerPageFuture}
                            />
                        </div>
                    </div>
                </div>

                <CreateBrokerTransaction open={open} handleOpen={handleOpen} setIsLoading={setIsLoading} />
            </AppLayout>

        </>
    )
}

export default BrokerTransactionsDailyBoard

