import { buildUrl } from "../../../helpers/apiHelper";
import axios from "../../../lib/axios"

const baseUrl = '/api/service-provider-users';

const getServiceProviderUser = async (
    config,
    serviceProviderUserId,
    options = {
        with: []
    }
) => {
    return await axios
        .get(buildUrl(`${baseUrl}/${serviceProviderUserId}`, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return null
        })
}

const getServiceProviderUsers = async (
    config,
    options = {
        with: [],
        where: {}
    }
) => {
    return await axios
        .get(buildUrl(baseUrl, options), config)
        .then(({ data }) => data)
        .catch(({ response }) => {
            console.error(response)
            return []
        })
}

const serviceProviderUserAPI = {
    getServiceProviderUsers,
    getServiceProviderUser
}

export default serviceProviderUserAPI
