import {
    Modal,
    Box,
    TextField,
    Autocomplete,
    Tab
} from "@mui/material"
import { useState, useEffect } from "react"
import Swal from "sweetalert2"
import { handleAxiosError } from "../../../helpers/helper"
import { AUTOCOMPLETE_STYLE, TEXTFIELD_STYLE } from "../../../constants/constants"
import style from '../../../styles/style'
import { t } from "i18next"
import axios from "../../../lib/axios"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

const CreateServiceProvider = ({
    open,
    handleClose,
    setIsLoading,
    config,
    companyId
}) => {
    const [name, setName] = useState("")
    const [tabValue, setTabValue] = useState('general')

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13' && open) {
                createServiceProvider()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, name]);

    const createServiceProvider = async () => {
        setIsLoading(true)
        const formData = new FormData()

        formData.append('name', name)
        formData.append('company_id', companyId)

        await axios
            .post(`/api/service-providers`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setName('')
                handleClose()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{...style, overflowX: 'hidden'}}>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_x', {x: t('service_provider')})}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <TabContext value={tabValue}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleTabValueChange} aria-label="service-provider-tabs" variant="scrollable">
                                <Tab sx={{textTransform: 'none'}} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition="start" />
                                <Tab sx={{textTransform: 'none'}} value="users" label={t('users')} icon={<i className="fa-solid fa-users-rays fa-lg"></i>} iconPosition="start" disabled={true} />
                            </TabList>
                        </Box>
                        <TabPanel value="general">
                            <form className='flex justify-center flex-col items-start mt-2'>
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel value="users"></TabPanel>
                    </TabContext>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={createServiceProvider} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default CreateServiceProvider
