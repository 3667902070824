import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'
import { useStateContext } from '../../context/ContextProvider'

const Navigation = ({ type = 'list', route, user }) => {
    const { t } = useTranslation()
    const { menuItems } = useStateContext()

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    const [showSalesOrdersTab, setShowSalesOrdersTab] = useState(false)
    const [showPurchaseOrdersTab, setShowPurchaseOrdersTab] = useState(false)
    const [showBrokerTranscationsTab, setShowBrokerTransactionsTab] = useState(false)

    useEffect(() => {
        if(menuItems.length > 0) {
            const salesOrdersMenu = menuItems.find((mi) => mi.url === (!['vendor_user', 'service_provider_user'].includes(user?.role) ? '/sales-order' : '/sales-orders'))
            const purchaseOrdersMenu = menuItems.find((mi) => mi.url === (!['vendor_user', 'service_provider_user'].includes(user?.role) ? '/purchase-order' : '/purchase-orders'))
            const brokerTransactionsMenu = menuItems.find((mi) => mi.url === '/broker-transactions')

            setShowSalesOrdersTab(salesOrdersMenu?.is_active == 1 ? true : false)
            setShowPurchaseOrdersTab(purchaseOrdersMenu?.is_active == 1 ? true : false)
            setShowBrokerTransactionsTab(brokerTransactionsMenu?.is_active == 1 ? true : false)
        }
    }, [menuItems])

    return (
        <div className='w-full flex items-center gap-1 p-6 pt-0' >
            <div className='flex w-full items-center justify-center px-1 rounded-md bg-[#ededf1]' style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }}>
                {
                    (
                        showSalesOrdersTab
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to={!['vendor_user', 'service_provider_user'].includes(user?.role) ? '/sales-order' : '/sales-orders'} className='w-full'>
                            <div className={`w-full text-center m-1 ml-0
                            ${includes(['/sales-order', '/sales-orders', '/sales-order-calendar', '/sales-orders-calendar'], window.location.pathname) ? 'bg-white rounded-sm' : 'border-r-2'}`}
                                style={{
                                    border: includes(['/sales-order', '/sales-orders'], window.location.pathname) ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: includes(['/sales-order', '/sales-orders'], window.location.pathname) ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('sales_orders')}</p>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    (
                        showPurchaseOrdersTab
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to={!['vendor_user', 'service_provider_user'].includes(user?.role) ? '/purchase-order' : '/purchase-orders'} className='w-full'>
                            <div className={`w-full text-center m-1 ml-0 mr-0
                            ${includes(['/purchase-order', '/purchase-orders', '/purchase-order-calendar', '/purchase-orders-calendar'], window.location.pathname) ? 'bg-white rounded-sm' : ''}`}
                                style={{
                                    border: includes(['/purchase-order', '/purchase-orders'], window.location.pathname) ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: includes(['/purchase-order', '/purchase-orders'], window.location.pathname) ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('purchase_orders')}</p>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    (
                        (showBrokerTranscationsTab && includes(['master_admin', 'office_manager'], user?.role))
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to='/broker-transactions' className='w-full'>
                            <div className={`w-full text-center m-1 ml-0
                            ${window.location.pathname == '/broker-transactions' || window.location.pathname == '/broker-transactions-calendar' ? 'bg-white rounded-sm' : 'border-l-2'}`}
                                style={{
                                    border: window.location.pathname == '/broker-transactions' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: window.location.pathname == '/broker-transactions' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('broker_transactions')}</p>
                            </div>
                        </Link>
                    ) : null
                }
            </div >
            <div className='flex w-fit items-center justify-center rounded-md px-1 bg-[#ededf1]' style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }}>
                <Link to={`/${route}`} className='w-full'>
                    <div className={`w-full text-center m-1 mr-0 ml-0 ${type == 'list' ? 'bg-white rounded-sm' : ''}`}
                        style={{
                            border: type == 'list' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                            boxShadow: type == 'list' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                        }}>
                        <p className='p-1.5 px-3'><i className="fa-solid fa-list"></i></p>
                    </div>
                </Link>
                <Link to={`/${route}-calendar`} className='w-full'>
                    <div className={`w-full text-center m-1 ml-0 ${type == 'calendar' ? 'bg-white rounded-sm' : 'hover:bg-[#D8D8D8] rounded-sm'}`}
                        style={{
                            border: type == 'calendar' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                            boxShadow: type == 'calendar' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                        }}>
                        <p className='p-1.5 px-3'><i className="fa-regular fa-calendar"></i></p>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default Navigation
