import React from 'react'

import { useTranslation } from 'react-i18next'

const BrokerSalesOrderButtons = ({ status, confirm, immutable, disabled, blocked, user }) => {

    const { t } = useTranslation()

    return (
        <>
            {
                (
                    status == 9
                    && ['vendor_user', 'service_provider_user'].includes(user?.role)
                    && !immutable
                ) ? (
                    <button type="button" onClick={confirm} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                        <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('confirm')}
                    </button>
                ) : null
            }
        </>
    )
}

export default BrokerSalesOrderButtons
