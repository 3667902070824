import React, {useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, Button, FormControl, MenuItem, Modal, Select, Tab, TextField, Tooltip, Stack} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import {useAuth} from '../../hooks/auth'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddSalesOrderItem from './AddSalesOrderItem'
import Loading from '../Loading'
import LoadingTruckIcon from '../LoadingTruckIcon'
import SalesOrderButtons from '../order_buttons/SalesOrderButtons'
import TPLOrderButtons from '../order_buttons/TPLOrderButtons'
import CustomerPickupButtons from '../order_buttons/CustomerPickupButtons'
import BrokeredSalesOrderButtons from '../order_buttons/BrokeredSalesOrderButtons'
import CommentHistoryModal from '../CommentHistoryModal'
import CommentHistoryButton from '../CommentHistoryButton'
import {isNil, toNumber, includes} from 'lodash'
import { createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleZero, isValid } from '../../helpers/helper'
import update from 'immutability-helper'

const EditSalesOrder = () => {

    const { t } = useTranslation()
    const { company_id, config, setCompany_id, setChoosesite, configFile, currencySymbol, pusher, features } = useStateContext()
    const params = useParams()
    const { user } = useAuth({ middleware: 'guest' })
    const navigate = useNavigate()
    const location = useLocation()

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    /* state */
    const [salesOrder, setSalesOrder] = useState([])
    const [shipToLocations, setShipToLocations] = useState([])
    const [salesOrderItems, setSalesOrderItems] = useState([])
    const [openSalesOrderItem, setOpenSalesOrderItem] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [disabledFields, setDisabledFields] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [value, setValue] = useState("1")
    const [prevStatus, setPrevStatus] = useState('')
    const [blocked, setBlocked] = useState(false)
    const [driverDocs, setDriverDocs] = useState([])
    const [driverBolDoc, setDriverBolDoc] = useState(null)
    const [hasDocs, setHasDocs] = useState(false)
    const [uploadedBillOfLadingDoc, setUploadedBillOfLadingDoc] = useState({})
    const [forklifters, setForklifters] = useState([])
    const [listOf3PLVendors, setListOf3PLVendors] = useState([])
    const [brokerList, setBrokerList] = useState([])
    const [openSendMailModal, setOpenSendMailModal] = useState(false)
    const [emails, setEmails] = useState('')
    const [brokerTransaction, setBrokerTransaction] = useState(null)

    const [openCommentHistory, setOpenCommentHistory] = useState(false)

    /* form */
    const [customer, setCustomer] = useState('')
    const [vendor3pl, setVendor3pl] = useState('')
    const [vendor3plName, setVendor3plName] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [isCustomerDeleted, setIsCustomerDeleted] = useState(false)
    const [shipToCode, setShipToCode] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs())
    const [shippedDate, setShippedDate] = useState(dayjs())
    const [customerPoNo, setCustomerPoNo] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [status, setStatus] = useState('')
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])
    const [file, setFile] = useState('')
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [SCItems, setSCItems] = useState([])
    const [forklifter, setForklifter] = useState('')
    const [forklifterName, setForklifterName] = useState('')
    const [loadedDate, setLoadedDate] = useState(dayjs())
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs())
    const [rejectedReason, setRejectedReason] = useState('')
    const [deliveryTicketNo, setDeliveryTicketNo] = useState('')
    const [brokerId, setBrokerId] = useState('')
    const [brokerName, setBrokerName] = useState('')

    useEffect(() => {
        const channelSalesOrderUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
        const channelSalesOrderDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
        const channelCommentCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-sales`);

        channelSalesOrderUpdated.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getSalesOrder(data.id, true)
            }
        })
        channelSalesOrderDeleted.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/sales-order', {
                    replace: true
                })
            }
        })

        channelCommentCreated.bind(`${localStorage.getItem('client_id')}-comment-created-event`, data => {
            getComments()
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-sales-${params.id}-document-event`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-sales`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        const orderDocumentUploadedChannelName = brokerTransaction
            ? `${localStorage.getItem('client_id')}-broker-${brokerTransaction.id}-document-event`
            : `${localStorage.getItem('client_id')}-sales-${params.id}-document-event`

        const channelOrderDocumentUploaded = pusher.subscribe(orderDocumentUploadedChannelName)

        const orderDocumentUploadedEventName = brokerTransaction
            ? `${localStorage.getItem('client_id')}-broker-${brokerTransaction.id}-document-event`
            : `${localStorage.getItem('client_id')}-sales-${params.id}-document-event`

        channelOrderDocumentUploaded.bind(orderDocumentUploadedEventName, data => {
            if(data.is_deleted) {
                setOrderDocuments((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setDriverDocs((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setUploadedBillOfLadingDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
            } else {
                getDocs(brokerTransaction)
                getDriverDocs(brokerTransaction)
                getUploadedBillOfLadingDoc(brokerTransaction)
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-sales-${params.id}-document-event`)
            if(brokerTransaction) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-broker-${brokerTransaction.id}-document-event`)
            }
        })
    }, [brokerTransaction])

    useEffect(() => {
        if(isValid(customer)) {
            const channelShipLocationCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
            const channelShipLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            const channelShipLocationDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            const channelCustomerDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)

            channelShipLocationCreated.bind(`${localStorage.getItem('client_id')}-shipaddress-created-event`, data => {
                if(Number(data?.customer_id) == Number(customer)) {
                    getShipToLocation(data?.id)
                }
            })
            channelShipLocationUpdated.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(data?.customer_id) == Number(customer)) {
                    getShipToLocations(customer)
                }
            })
            channelShipLocationDeleted.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if(Number(data?.customer_id) == Number(customer)) {
                    setShipToLocations((prev) => {
                        const item = prev.find((i) => i.id === data.id)
                        const exItem = prev.filter((i) => i.id !== item.id)
                        return exItem
                    })
                }
            })
            channelCustomerDeleted.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
                if(Number(data?.id) == Number(customer)) {
                    setIsCustomerDeleted(true)
                }
            })
        }

        return (() => {
            if(isValid(customer)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            }
        })
    }, [customer])

    useEffect(() => {
        if(!isNil(brokerTransaction)) {
            const channelBrokerTransactionUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)

            channelBrokerTransactionUpdated.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(brokerTransaction?.id)) {
                    getSalesOrder(params.id, true)
                }
            })
        }

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [brokerTransaction])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* documents */
    const [orderDocuments, setOrderDocuments] = useState([])

    useEffect(() => {
        getSalesOrder(params.id)
    }, [params.id])

    const getSalesOrder = async (id, dueToPusherEvent = false) => {
        setIsLoading(true)

        if(dueToPusherEvent) {
            setCustomer('')
        }

        await axios
            .get(`/api/sales-orders/${id}?with=broker,brokerTransaction`, config)
            .then(res => {
                const salesOrder = res.data
                setStatus(salesOrder.so_status_id)
                setPrevStatus(salesOrder.prev_status_id || '')
                setCustomer(salesOrder.customer_id)
                setCustomerName(salesOrder.customer_name)
                setIsCustomerDeleted(salesOrder.is_customer_deleted || false)
                setDropTrailerNo(salesOrder.drop_trailer_no || '')
                setPickupTrailerNo(salesOrder.pickup_trailer_no || '')
                setCustomerPoNo(salesOrder.customer_po_no)
                setRequestedDeliveryDate(dayjs(salesOrder.requested_delivery_date))
                setRequestedDeliveryTime(dayjs(salesOrder.requested_delivery_time))
                setShippedDate(dayjs(salesOrder.shipment_date))
                setSalesOrderItems(salesOrder.sales_order_items)
                setCommentHistory(salesOrder.comment_history)
                setForklifter(salesOrder.forklift_driver_id || '')
                setForklifterName(salesOrder.forklift_driver_name || '')
                setLoadedDate(dayjs(salesOrder.loaded_date) || dayjs())
                setSalesOrder(salesOrder)
                setVendor3pl(salesOrder.tpl_vendor_id || '')
                setVendor3plName(salesOrder.tpl_vendor_name || '')
                setRejectedReason(salesOrder.rejected_data.rejected_reason || '')
                setDeliveryTicketNo(salesOrder.delivery_ticket_no || '')
                setBrokerId(salesOrder.broker?.id ?? '')
                setBrokerName(salesOrder.broker?.name ?? '')
                setBrokerTransaction(salesOrder?.broker_transaction)
                setDriverBolDoc(salesOrder?.uploaded_driver_bol)
                if (!dueToPusherEvent) {
                    setComments('')
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* UseEffect */
    useEffect(() => {
        getDocs(brokerTransaction)
        getDriverDocs(brokerTransaction)
        getUploadedBillOfLadingDoc(brokerTransaction)
    }, [brokerTransaction])

    useEffect(() => {
        switch(salesOrder?.type) {
            case 'CUSTOMER PICKUP':
                getForklifters()
                break
            case '3PL':
                if(isValid(company_id)) {
                    get3PLVendors(company_id)
                }
                break
            case 'BROKERED':
                if(isValid(company_id)) {
                    getBrokerVendors(company_id)
                }
                break
            default:
        }
    }, [salesOrder, company_id])

    useEffect(() => {
        if (
            (status === 1 || status === 3)
            && (
                ['master_admin', 'office_manager'].includes(user?.role)
                || userHasPermissions(['daily-board-update'])
            )
        ) {
            setDisabledFields(false)
        } else {
            setDisabledFields(true)
        }
    }, [status])

    useEffect(() => {
        if (SCItems.length > 0) {
            handleNewItem(SCItems)
        }
    }, [SCItems])

    useEffect(() => {
        loadShipToLocation()
    }, [shipToLocations])

    useEffect(() => {
        if (isValid(customer)) {
            getShipToLocations(customer)
        }
    }, [customer])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [salesOrderItems, params])

    useEffect(() => {
        const items = salesOrderItems.filter((item) => item.qty_ordered !== "")

        if (items) {
            const sum = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            if (sum > 0) {
                setBlocked(false)
            }
            else setBlocked(true)
        }

    }, [salesOrderItems])



    useEffect(() => {
        setSalesOrderItems([])
        if (shipToCode == salesOrder?.ship_address_id) {
            setSalesOrderItems(salesOrder?.sales_order_items)
        }
        else {
            if (shipToLocations.length > 0 && customer && shipToCode) {
                getSalesContractItems()
            }
        }
    }, [shipToCode])


    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])


    /* Handlers */

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleTotalPrice = () => {
        const sum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        //proci kroz sales iteme i vidjeti ima li koji qty == "" ako ima skip
        const item = salesOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }


    const handleCustomerPoNo = (e) => {
        const limit = 50
        setCustomerPoNo(e.target.value.slice(0, limit))
    }

    const loadShipToLocation = () => {
        const shipToLocation = shipToLocations?.find(target => target.id === shipToCode)
        setAddress(shipToLocation?.address)
        setZip(shipToLocation?.zip)
        setCity(shipToLocation?.city)
        setState(shipToLocation?.state)
        setDeliveryInstructions(shipToLocation?.delivery_instructions)
    }

    const handleShipToLocation = (e) => {
        setShipToCode(e.target.value)
        const shipToLocation = shipToLocations.find(target => target.id === e.target.value)
        setAddress(shipToLocation?.address)
        setZip(shipToLocation?.zip)
        setCity(shipToLocation?.city)
        setState(shipToLocation?.state)
        setDeliveryInstructions(shipToLocation?.delivery_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const salesOrderItemsCopy = [...salesOrderItems]
        const item = salesOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setSalesOrderItems(salesOrderItemsCopy)
    }

    const handleOpenSalesOrderItem = () => {
        setDisabled(true)
        setOpenSalesOrderItem(!openSalesOrderItem)
    }

    const handleUploadClick = () => {
        inputRef.current?.click()
    }

    const handleNewItem = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_ship": 0,
                "qty_shipped": 0,
                "qty_loaded": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "unit_id": item.item_id,
                "color": item.color,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setSalesOrderItems((prev) => [...prev, new_item])
        })
    }
    /* API */

    const getSalesContractItems = async () => {
        setIsLoading(true)
        await axios.get(`/api/sales-contracts?customer_id=${customer}&ship_address_id=${shipToCode}`, config)
            .then(res => {
                const items = res.data
                setSCItems(items[0]?.sales_contract_items || [])
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getForklifters = async () => {
        setIsLoading(true)
        await axios.get(`/api/users?role=forklift_driver&site_id=${localStorage.getItem('site')}`, config)
            .then(res => {
                const users = res.data.data
                setForklifters(users)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const get3PLVendors = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&shipping_code=3PL&blocked=0`, config)
            .then(res => {
                const vendors = res.data
                setListOf3PLVendors(vendors)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getBrokerVendors = async (companyId) => {
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${companyId}&shipping_code=BROKER&blocked=0`, config)
            .then(res => {
                const data = res.data
                setBrokerList(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDocs = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=order_document`
            : `/api/order-documents?order_id=${params.id}&order_type=sales&document_type=order_document`

        await axios
            .get(url, config)
            .then(res => {
                const files = res.data
                setOrderDocuments(files)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDriverDocs = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=drivers_document`
            : `/api/order-documents?order_id=${params.id}&order_type=sales&document_type=drivers_document`

        await axios
            .get(url, config)
            .then(res => {
                const files = res.data
                setDriverDocs(files)
                if (files.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedBillOfLadingDoc = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=proof_of_delivery`
            : `/api/order-documents?order_id=${params.id}&order_type=sales&document_type=proof_of_delivery`

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedBillOfLadingDoc({})
                } else {
                    setUploadedBillOfLadingDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getComments = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/comments?order_id=${params.id}&order_type=sales`)
            .then(res => {
                const data = res.data
                setCommentHistory(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDocs = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_docs') + name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        setIsLoading(true)
        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);

        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('order_id', params.id)
        formData.append('order_type', 'sales')
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                inputRef.current.value = ""
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses/${id}`, config)
            .then((res) => {
                const shipToLocation = res.data
                setShipToLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, shipToLocation]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: shipToLocation } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const shipToLocations = res.data
                setShipToLocations(shipToLocations)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
        setShipToCode(salesOrder.ship_address_id)
    }

    const updateReopenStatus = async (e) => {
        e.preventDefault()
        setDisabledFields(false)
        setStatus(3)
        setIsLoading(true)

        const formData = {}

        formData['customer_id'] = customer
        formData['ship_address_id'] = shipToCode
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = salesOrder.site_id
        formData['sales_order_items'] = salesOrderItems
        formData['so_status_id'] = 3
        formData['prev_status_id'] = status
        formData['so_number'] = salesOrder.so_number
        formData['comments'] = comments
        formData['delivery_ticket_no'] = deliveryTicketNo

        switch(salesOrder?.type) {
            case 'CUSTOMER PICKUP':
                formData['forklift_driver_id'] = forklifter
                formData['loaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                formData['type'] = "CUSTOMER PICKUP"
                break
            case '3PL':
                formData['tpl_vendor_id'] = vendor3pl
                formData['type'] = "3PL"
                break
            case 'BROKERED':
                formData['broker_id'] = brokerId
                formData['type'] = "BROKERED"
                break
            default:
        }

        await axios.put(`/api/sales-orders/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateReleaseStatus = async (e, shouldProceedAnyway = false) => {
        setIsLoading(true)
        e.preventDefault()

        const formData = {}

        formData['customer_id'] = customer
        formData['ship_address_id'] = shipToCode
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = salesOrder.site_id
        formData['sales_order_items'] = salesOrderItems
        formData['so_status_id'] = prevStatus > 3 ? prevStatus : 2
        formData['prev_status_id'] = null
        formData['so_number'] = salesOrder.so_number
        formData['comments'] = comments
        formData['proceed_anyway'] = shouldProceedAnyway
        formData['delivery_ticket_no'] = deliveryTicketNo

        switch(salesOrder?.type) {
            case 'CUSTOMER PICKUP':
                formData['forklift_driver_id'] = forklifter
                formData['loaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                formData['type'] = "CUSTOMER PICKUP"
                    break
            case '3PL':
                formData['tpl_vendor_id'] = vendor3pl
                formData['type'] = "3PL"
                break
            case 'BROKERED':
                formData['broker_id'] = brokerId
                formData['type'] = "BROKERED"
                break
            default:
        }

        await axios.put(`/api/sales-orders/${params.id}`, formData, config)
            .then(( data ) => {
                if(data.status === 202) {
                    Swal.fire({
                        title: t('caution'),
                        text: data.data.accepted?.description ?? '',
                        icon: 'warning',
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FEB806',
                        reverseButtons: true,
                        showCancelButton: true,

                        confirmButtonText: t('yes'),
                        cancelButtonText: t('negatory')
                    }).then((result) => {
                        if(result.isConfirmed) {
                            updateReleaseStatus(e, true)
                        }
                    })
                } else {
                    Swal.fire({
                        icon: "success",
                        customClass: 'success',
                        showCloseButton: true,
                        iconColor: '#00B78E',
                        text: data.data.success.message
                    })
                    setDisabledFields(true)
                    if (prevStatus > 3) {
                        setStatus(prevStatus)
                    } else {
                        setStatus(2)
                    }
                    setComments('')
                    /* getOrder(params.id) */
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateSalesOrder = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customer
        formData['ship_address_id'] = shipToCode
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = salesOrder.site_id
        formData['sales_order_items'] = salesOrderItems
        formData['so_status_id'] = status
        formData['so_number'] = salesOrder.so_number
        formData['comments'] = comments
        formData['delivery_ticket_no'] = deliveryTicketNo

        switch(salesOrder?.type) {
            case 'CUSTOMER PICKUP':
                formData['forklift_driver_id'] = forklifter
                formData['loaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                formData['type'] = "CUSTOMER PICKUP"
                    break
            case '3PL':
                formData['tpl_vendor_id'] = vendor3pl
                formData['type'] = "3PL"
                break
            case 'BROKERED':
                formData['broker_id'] = brokerId
                formData['type'] = "BROKERED"
                break
            default:
        }

        await axios.put(`/api/sales-orders/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const completeShipment = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['sales_order_items'] = salesOrderItems

        await axios.put(`/api/sales-orders/${params.id}/complete-from-relased`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const complete3PLShipment = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}
        formData['so_status_id'] = 11

        await axios.put(`/api/3pl/${params.id}/complete`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const setStatusToLoading = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['so_status_id'] = 5

        await axios.put(`/api/sales-orders/${params.id}/change-status`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const setStatusToLoaded = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['sales_order_items'] = salesOrderItems

        await axios.put(`/api/sales-orders/${params.id}/load-shipment`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const retriveLoad = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        await axios.post(`/api/sales-orders/${params.id}/retrieve-load`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const undoShipment = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}
        formData['sales_order_items'] = salesOrderItems

        await axios.put(`/api/sales-orders/${params.id}/undo-shipment-master-admin`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const markAsShiped = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}
        formData['sales_order_items'] = salesOrderItems

        await axios.put(`/api/sales-orders/${params.id}/mark-shipped-master-admin`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const clickGenerateInvoiceHandler = async (e) => {
        e.preventDefault()

        if(isCustomerDeleted) {
            Swal.fire({
                text: t('x_is_deleted_warning', {
                    x: t('customer')
                }),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showDenyButton: true,
                showCloseButton: false,
                confirmButtonText: t('yes'),
                denyButtonText: t('negatory'),
                allowOutsideClick: false
            }).then((result) => {
                if(result.isConfirmed) {
                    generateInvoice()
                }
            })
        } else {
            generateInvoice()
        }
    }

    const generateInvoice = async (e) => {
        setIsLoading(true)

        const formData = {}

        formData['sales_order_id'] = params.id

        await axios
            .post(`/api/quick-books/save-invoice`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const sendMail = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}

        formData['emails'] = emails
        formData['client_id'] = localStorage.getItem('client_id')

        await axios.post(`/api/3pl/${params.id}/email-pod`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: t('email_send')
                })
                handleToggleSendMailModal()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPodUrl = (
        stream = 'view',
        party = 'customer'
    ) => {
        let urlStem = `sales-orders/${params.id}`

        if(brokerTransaction) {
            urlStem = `broker-transactions/${brokerTransaction?.id}`
        }

        const urlRoot = `${process.env.REACT_APP_BACKEND_URL}/api`
        const urlLeaves = `proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`

        return `${urlRoot}/${urlStem}/${urlLeaves}`
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...salesOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(qts)


        setSalesOrderItems(purchaseOrderItemsCopy)
    }

    const handleToggleSendMailModal = () => {
        setOpenSendMailModal(!openSendMailModal)
        if(!openSendMailModal) {
            setEmails('')
        }
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('to_ship')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipCell params={params} t={t} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('shipped')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('unit')}</span>
                    <span>{t('price')}</span>
                </div>
            ), */
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.7,
            minWidth: 75,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} t={t} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} />
        },
    ]

    const columns2 = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('ordered')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('to_ship')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipCell params={params} t={t} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('shipped')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('unit')}</span>
                    <span>{t('price')}</span>
                </div>
            ), */
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        }
    ]

    const optionsForklifter = forklifters?.map(option => ({ label: option.name, id: option.id }))
    const optionsVendors = listOf3PLVendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))
    const optionsBrokers = brokerList?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <div className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span>
                            {
                                salesOrder?.type == 'CUSTOMER PICKUP' ?
                                    <p>
                                        {
                                            (
                                                (
                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                    || userHasPermissions(['daily-board-update'])
                                                )
                                                && !salesOrder?.rejected
                                                && salesOrder?.so_status_id < 9
                                            )
                                                ? t('update_sp')
                                                : t('view_sp')
                                        } - {salesOrder.so_number}
                                    </p>
                                    :
                                    salesOrder?.type == '3PL' ?
                                        <p>
                                            {
                                                (
                                                    (
                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                        || userHasPermissions(['daily-board-update'])
                                                    )
                                                    && !salesOrder?.rejected
                                                    && salesOrder?.so_status_id < 9
                                                )
                                                ? t('update_pl')
                                                : t('view_pl')
                                            } - {salesOrder.so_number}
                                        </p>
                                        :
                                        salesOrder?.type == 'BROKERED' ?
                                            <p>
                                                {
                                                    (
                                                        (
                                                            ['master_admin', 'office_manager'].includes(user?.role)
                                                            || userHasPermissions(['daily-board-update'])
                                                        )
                                                        && !salesOrder?.rejected
                                                        && salesOrder?.so_status_id < 9
                                                    )
                                                    ? t('update_bo')
                                                    : t('view_bo')
                                                } - {salesOrder.so_number}
                                            </p>
                                            :
                                            <p>
                                                {
                                                    (
                                                        (
                                                            ['master_admin', 'office_manager'].includes(user?.role)
                                                            || userHasPermissions(['daily-board-update'])
                                                        )
                                                        && !salesOrder?.rejected
                                                        && salesOrder?.so_status_id < 9
                                                    )
                                                    ? t('update_so')
                                                    : t('view_so')
                                                } - {salesOrder.so_number}
                                            </p>
                            }
                        </div>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={() => navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        {/* Status */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('status')}</p>
                            <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                {salesOrder.so_status_name}
                                {
                                    user.role === 'master_admin' ?
                                        salesOrder.so_status_id === 4 ? <Tooltip disableInteractive title={t('change_status_loading')}><button /* disabled={disabledFields} */ onClick={e => setStatusToLoading(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><LoadingTruckIcon shouldAnimate={false} size="small" /></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        salesOrder.so_status_id === 5 ? <Tooltip disableInteractive title={t('change_status_loaded')}><button /* disabled={disabledFields} */ onClick={e => setStatusToLoaded(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-truck"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        salesOrder.so_status_id === 7 || salesOrder.so_status_id === 8 ? <Tooltip disableInteractive title={t('retrive_load')}><button /* disabled={disabledFields} */ onClick={e => retriveLoad(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-rotate-right"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        salesOrder.so_status_id === 9 ? <Tooltip disableInteractive title={t('undo_shipment')}><button onClick={e => undoShipment(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-reply"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        salesOrder.so_status_id === 6 ? <Tooltip disableInteractive title={t('changeToShiped')}><button onClick={e => markAsShiped(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-check"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                            </div>
                        </div>
                        {/* Rejection Reasoning */}
                        {salesOrder?.rejected ? (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('reason_for_rejection')}</p>
                                <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{t(`${rejectedReason}`)}</div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Customer */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled className='w-full' name="drop_trailer_no" value={customerName} />
                            </div>
                        </div>

                        {/* Ship To Location */}

                        {brokerTransaction?.logistics_type_key != 'pick_up' ? (
                            <>
                                <div className='w-full pb-4'>
                                    <Stack
                                        direction='row'
                                        gap={1}
                                        alignItems='baseline'
                                        justifyContent='space-between'
                                    >
                                        <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                        {(shipToCode && isValid(deliveryInstructions)) ? (
                                            <Tooltip
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {deliveryInstructions}
                                                    </div>
                                                }
                                            >
                                                <Stack
                                                    direction='row'
                                                    gap={1}
                                                    alignItems='center'
                                                    className='text-[#718096]'
                                                >
                                                    <MenuBookIcon fontSize='18px'/>
                                                    <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                                </Stack>
                                            </Tooltip>
                                        ) : null}
                                    </Stack>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    }
                                                }}
                                                value={shipToCode}
                                                onChange={(e) => { handleShipToLocation(e) }} d
                                                disabled={disabledFields}
                                            >
                                                {shipToLocations?.map((item, index) => <MenuItem key={`stlid-${item.id}_${index}`} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {/* Address */}
                                {
                                    shipToCode ?
                                        <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                            <div className='w-full'>{address}</div>
                                            {shipToCode ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                        </div>
                                        :
                                        null
                                }
                            </>
                        ) : (
                            <div className='w-full p-4 address mb-4'>
                                <div className='w-full text-[#00000061]'>{t('pick_up')}</div>
                            </div>
                        )}

                        {/* 3pl vendor */}
                        {
                            salesOrder?.type == '3PL' ?
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('3pl_vendor')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            disabled={disabledFields}
                                            id="combo-box-demo"
                                            options={optionsVendors}
                                            value={{ label: vendor3plName, id: vendor3pl }}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            onChange={(a, b) => { setVendor3pl(b?.id || ''); setVendor3plName(b?.label || '') }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>
                                :
                                null
                        }

                        {/* BROKER */}
                        {
                            salesOrder?.type == 'BROKERED' ?
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('broker')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            disabled={disabledFields}
                                            id="broker-combo-box"
                                            options={optionsBrokers}
                                            value={{ label: brokerName, id: brokerId }}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            onChange={(a, b) => { setBrokerId(b?.id || ''); setBrokerName(b?.label || '') }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>
                                :
                                null
                        }

                        {/* Drop Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                            </div>
                        </div>

                        {/* Pickup Trailer No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                            </div>
                        </div>

                        {/* Customer PO No. */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('customer_po_no')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled={disabledFields} className='w-full' name="customer_po_no" value={customerPoNo} onChange={(e) => { handleCustomerPoNo(e) }} />
                                <p className='text-xs italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                            </div>
                        </div>

                        {/* Delivery Ticket No. */}
                        {
                                includes(['oliverswoodworking'], localStorage.getItem('client_id')) ? (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('delivery_ticket_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" disabled={disabledFields} className='w-full' name="delivery_ticket_no" value={deliveryTicketNo} onChange={(e) => { setDeliveryTicketNo(e.target.value) }} />
                                        </div>
                                    </div>
                                ) : null
                        }

                        <div className='lg:flex gap-3 w-full'>
                            {/* Requested Delivery Date */}
                            <div className={`w-full ${(brokerTransaction?.logistics_type_key == 'delivery' && brokerTransaction?.delivery_time == 'appointment') ? 'lg:w-1/2' : ''} pb-4`}>
                                <p className="text-[14px] text-[#718096]">{t('requested_delivery_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker disabled={disabledFields}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" value={requestedDeliveryDate} onChange={(newValue) => setRequestedDeliveryDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            {
                                (
                                    !brokerTransaction
                                    || (includes(['delivery', 'tpl'], brokerTransaction?.logistics_type_key) && brokerTransaction?.delivery_time == 'appointment')
                                ) ? (
                                    <div className={`w-full lg:w-1/2 pb-4`}>
                                        <p className="text-[14px] text-[#718096]">{t('requested_shipment_time')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                    <MobileTimePicker disabled={disabledFields}
                                                        sx={{
                                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                        }}
                                                        value={requestedDeliveryTime} onChange={(newValue) => setRequestedDeliveryTime(newValue)} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>

                        {/* Shipment Date */}
                        {
                            (salesOrder?.type === 'BROKER' && !localStorage.getItem('client_id').includes('pallet-book'))
                            || (salesOrder?.type !== 'CUSTOMER PICKUP' && salesOrder?.type !== 'BROKER')
                            && (
                                <div className={`w-full pb-4`}>
                                    <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker disabled={disabledFields}
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                format="YYYY-MM-DD" value={shippedDate} onChange={(newValue) => setShippedDate(newValue)} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            )
                        }

                        {
                            salesOrder?.type === 'CUSTOMER PICKUP' ? (
                                <>
                                    {/* Shipment Date */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker disabled={disabledFields}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    format="YYYY-MM-DD" value={shippedDate} onChange={(newValue) => setShippedDate(newValue)} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    {/* Loaded Date */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('loaded_date')} *</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker disabled={disabledFields}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    format="YYYY-MM-DD" value={loadedDate} onChange={(newValue) => setLoadedDate(newValue)} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    {/* Forklifters */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('forklift_driver')} *</p>
                                        <div className='w-full'>
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                disabled={disabledFields}
                                                id="combo-box-demo"
                                                options={optionsForklifter}
                                                value={{ label: forklifterName, id: forklifter }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                onChange={(a, b) => { setForklifter(b?.id || ''); setForklifterName(b?.label) }}
                                                renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {
                                        salesOrder.forklift_driver_name ?
                                            salesOrder.so_status_id > 3 || salesOrder.so_status_id === 3 ?
                                                <div className={`w-full pb-4`}>
                                                    <p className="text-[14px] text-[#718096]">{t('forklift_driver')}</p>
                                                    <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{salesOrder.forklift_driver_name}</div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                    {
                                        salesOrder.driver_name ?
                                            salesOrder.so_status_id > 6 || salesOrder.so_status_id === 3 ?
                                                <div className={`w-full pb-4`}>
                                                    <p className="text-[14px] text-[#718096]">{t('driver')}</p>
                                                    <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{salesOrder.driver_name}</div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                            <div className='pt-1 pl-5'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                            </div>
                            <div className='flex justify-end p-5 pt-0 border-b'>
                                <Tooltip disableInteractive title={t('addsalesOrderitems')} placement='bottom'>
                                    <div>
                                        <AddButton disabled={disabledFields} onClick={handleOpenSalesOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='border-b'>
                                {
                                    salesOrderItems.length > 0 ?
                                        (
                                            (status == 1 || status == 3)
                                            && (
                                                ['master_admin', 'office_manager'].includes(user?.role)
                                                || userHasPermissions(['daily-board-update'])
                                            )
                                        ) ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setSalesOrderItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                        :
                                        null
                                }
                                <TableShared columns={disabledFields ? columns2 : columns} items={salesOrderItems} disableColumnMenu={true} />
                            </div>
                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                        <p>{t('totalPrice')}</p>
                                        <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{totalQty}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block gap-3 lg:flex'>
                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2 h-fit'>
                                <div className='pt-1 p-5 flex justify-between items-start'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={commentHistory?.length ?? ''}
                                        backgroundColor='#336195'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <div className='w-full p-5 pt-0'>
                                    <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        value={comments}
                                        disabled={disabledFields}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                </div>
                                <div className='w-full'>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="document tab list" variant='scrollable'>
                                                <Tab sx={{ textTransform: 'none' }} value="1" label={t('order_documents')} />
                                                <Tab sx={{ textTransform: 'none' }} value="2" label={t('bill_of_lading')} />
                                                {salesOrder?.type != 'BROKER' && (
                                                    <Tab sx={{ textTransform: 'none' }} value="3" label={t('load_sheet')} />
                                                )}
                                                {(
                                                    features?.INVOICING == 1
                                                    && salesOrder?.type != 'BROKER'
                                                ) ? (
                                                    <Tab sx={{ textTransform: 'none' }} value="4" label={t('invoice')} />
                                                ) : null}
                                            </TabList>
                                        </Box>
                                        {/* ORDER DOCUMENTS */}
                                        <TabPanel value="1">
                                            <div className='w-full text-center'>
                                                <Button onClick={handleUploadClick} disabled={disabledFields} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                    <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                </Button>
                                                <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'order_document')} />
                                            </div>
                                            <>
                                                {
                                                    orderDocuments?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                    ) ? (
                                                                        <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {hasDocs ? (
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_documents')}</div>
                                                ) : (
                                                    ''
                                                )}
                                                {
                                                    driverDocs?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                    ) ? (
                                                                        <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </TabPanel>

                                        {/* PROOFS OF DELIVERY */}
                                        <TabPanel value="2">

                                            {/* Auto-generated Bills of Lading */}

                                            {(
                                                Object.keys(uploadedBillOfLadingDoc).length <= 0
                                                && !driverBolDoc
                                            ) && (
                                                <div className='flex flex-col gap-2 pb-5'>
                                                    <div className='pt-5 flex justify-between'>
                                                        <p className='text-blue-900 underline'><a
                                                            href={getPodUrl()}
                                                            target="_blank"><span className="text-[#336195]"
                                                                style={{ cursor: 'pointer' }}>{t('bill_of_lading')}</span></a>
                                                        </p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={getPodUrl()}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-eye"></i></Tooltip></button>
                                                            </a>
                                                            <a href={getPodUrl('download')}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-download"></i></Tooltip></button>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {
                                                        salesOrder?.type === '3PL' && (
                                                            <div className='pt-5 flex justify-between'>
                                                                <p className='text-blue-900 underline'><a
                                                                    href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                    target="_blank"><span className="text-[#336195]"
                                                                        style={{ cursor: 'pointer' }}>3PL {t('bill_of_lading')}</span></a>
                                                                </p>
                                                                <div className='flex items-center gap-3'>
                                                                    <button className='text-neutral-500' onClick={handleToggleSendMailModal}>
                                                                        <Tooltip disableInteractive title={t('send_email')} placement='bottom'>
                                                                            <i className="fa-solid fa-envelope"></i>
                                                                        </Tooltip>
                                                                    </button>
                                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                        target="_blank" download>
                                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                                            title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                                className="fa-solid fa-eye"></i></Tooltip></button>
                                                                    </a>
                                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                                        target="_blank" download>
                                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                                            title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                                className="fa-solid fa-download"></i></Tooltip></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}

                                            {/* Manually-uploaded Bills of Lading */}

                                            {Object.keys(uploadedBillOfLadingDoc).length > 0 && (
                                                <>
                                                    <div className='text-sm text-slate-600'>{t('uploaded_x', { x: t('bill_of_lading') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedBillOfLadingDoc.document_url} target="_blank" download>{uploadedBillOfLadingDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedBillOfLadingDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                ) ? (
                                                                    <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(uploadedBillOfLadingDoc.id, uploadedBillOfLadingDoc.document_name)}><Tooltip disableInteractive title={t('delete_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {
                                                (
                                                    Object.keys(uploadedBillOfLadingDoc).length <= 0
                                                    && (
                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                        || userHasPermissions(['daily-board-update'])
                                                    )
                                                ) ? (
                                                    <div className={`pt-5 w-full text-center`}>
                                                        <Button onClick={handleUploadClick} disabled={disabledFields} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                            <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('BoL') })}</p> </div>
                                                        </Button>
                                                        <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'proof_of_delivery')} />
                                                    </div>
                                                ) : null
                                            }

                                            {driverBolDoc && (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_bill_of_lading')}</div>

                                                    <div className='pt-3 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3' ><a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download>{driverBolDoc?.document_name}</a></p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('download_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-download"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </TabPanel>

                                        {/* LOAD SHEETS */}
                                        <TabPanel value="3">
                                            <div className='pt-5 flex justify-between'>
                                                <p className='text-blue-900 underline'><a
                                                    href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/load-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                    target="_blank"><span className="text-[#336195]"
                                                        style={{ cursor: 'pointer' }}>{t('load_sheet')}</span></a>
                                                </p>
                                                <div className='flex items-center gap-3'>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/load-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('view_x', { x: t('load_sheet') })} placement='bottom'><i
                                                                className="fa-solid fa-eye"></i></Tooltip></button>
                                                    </a>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/load-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('download_x', { x: t('load_sheet') })} placement='bottom'><i
                                                                className="fa-solid fa-download"></i></Tooltip></button>
                                                    </a>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        {/* INVOICES */}
                                        <TabPanel value="4">
                                            <div className='pt-5 flex justify-between'>
                                                <p className='text-blue-900 underline'><a
                                                    href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/invoice?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                    target="_blank"><span className="text-[#336195]"
                                                        style={{ cursor: 'pointer' }}>{t('invoice')}</span></a>
                                                </p>
                                                <div className='flex items-center gap-3'>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/invoice?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('view_x', { x: t('invoice') })} placement='bottom'><i
                                                                className="fa-solid fa-eye"></i></Tooltip></button>
                                                    </a>
                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/sales-orders/${params.id}/invoice?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                        target="_blank" download>
                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                            title={t('download_x', { x: t('invoice') })} placement='bottom'><i
                                                                className="fa-solid fa-download"></i></Tooltip></button>
                                                    </a>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-3'>
                            {
                                (
                                    (
                                        ['master_admin', 'office_manager'].includes(user?.role)
                                        || userHasPermissions(['daily-board-update'])
                                    ) &&
                                    !salesOrder?.rejected
                                ) ? (
                                    <>
                                        {
                                            salesOrder?.type == 'SHIPMENT' ?
                                                <SalesOrderButtons status={status} update={updateSalesOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateInvoice={clickGenerateInvoiceHandler} disabled={disabledFields} blocked={blocked} />
                                                :
                                                null
                                        }
                                        {
                                            salesOrder?.type == '3PL' ?
                                                <TPLOrderButtons status={status} update={updateSalesOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateInvoice={clickGenerateInvoiceHandler} disabled={disabledFields} blocked={blocked} complete3PLShipment={complete3PLShipment} />
                                                :
                                                null
                                        }
                                        {
                                            salesOrder?.type == 'CUSTOMER PICKUP' ?
                                                <CustomerPickupButtons status={status} update={updateSalesOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateInvoice={clickGenerateInvoiceHandler} disabled={disabledFields} blocked={blocked} completeShipment={completeShipment} />
                                                :
                                                null
                                        }
                                        {
                                            salesOrder?.type == 'BROKERED' ?
                                                <BrokeredSalesOrderButtons status={status} update={updateSalesOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateInvoice={clickGenerateInvoiceHandler} disabled={disabledFields} blocked={blocked} />
                                                :
                                                null
                                        }
                                    </>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                </div>
            </Box>
            <AddSalesOrderItem open={openSalesOrderItem} handleOpen={handleOpenSalesOrderItem} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} disabled={disabled} setDisabled={setDisabled} />
            {/* Send (3PL) Email modal  */}
            <Modal open={openSendMailModal} onClose={handleToggleSendMailModal}>
                <Box sx={styles}>
                    <div className='p-5' style={{ backgroundColor: '#df9133', borderRadius: '5px 5px 0 0' }}>
                        <div className='flex gap-4 items-baseline justify-between'>
                            <p className='text-xl roboto font-semibold text-white'>{salesOrder.so_number} - {salesOrder?.tpl_vendor_name}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-semibold text-white">
                                <button onClick={handleToggleSendMailModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='p-5'>
                            <form className='flex justify-center flex-col items-start mt-2'>
                                <div className='pb-5 w-full'>
                                    <TextField type="text" label={t('email_addresses')} variant='standard' className='w-full' name="emails" value={emails} onChange={(e) => { setEmails(e.target.value) }} />
                                </div>
                            </form>
                            <div className='flex justify-end gap-4'>
                                <Button variant="contained" onClick={sendMail} sx={{ backgroundColor: '#df9133', "&:disabled": { backgroundColor: "[hsla(0,0%,100%,.12)]" }, "&:hover": { backgroundColor: "#df9133" }, width: '120px' }}>{t('send_email')}</Button>
                                <Button variant="contained" onClick={handleToggleSendMailModal} sx={{ backgroundColor: '#df9133', "&:disabled": { backgroundColor: "[hsla(0,0%,100%,.12)]" }, "&:hover": { backgroundColor: "#df9133" }, width: '120px' }}>{t('cancel')}</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default EditSalesOrder

const maxQtyDigits = 5

const QtyOrderedCell = ({ params, handler, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)
            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_ship) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_ship
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }

        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled}
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )

}

const QtyToShipCell = ({ params, t, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_ship])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        icon: "warning",
                        text: t('warningSalesOrder'),
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            disabled={disabled}
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-ship"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )

}


const TotalCell = (params) => {

    const [total, setTotal] = useState(params.params.value)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const UnitPriceCell = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const QtyShippedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_shipped])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-shipped"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            }) }
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            }) }
            disabled
        />
    )

}


const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}

const Actions = ({ params, t, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
