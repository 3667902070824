import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, FormControl, MenuItem, Select, Tab, TextField, Tooltip, Stack, Autocomplete, Modal } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import { useAuth } from '../../hooks/auth'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddSalesOrderItem from '../sales_purchase_orders/AddSalesOrderItem'
import Loading from '../Loading'
import CommentHistoryModal from '../CommentHistoryModal'
import CommentHistoryButton from '../CommentHistoryButton'
import AddPurchaseOrderItem from '../sales_purchase_orders/AddPurchaseOrderItem'
import { areAllValid, isValid, createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleNumber, handleZero } from '../../helpers/helper'
import { toNumber, isNil, includes, toString } from 'lodash';
import update from 'immutability-helper'
import BrokerTransactionEmailDraftsModal from '../BrokerTransactionEmailDraftsModal'
import { AUTOCOMPLETE_STYLE, MOBILE_DATE_PICKER_STYLE, MOBILE_TIME_PICKER_STYLE, SELECT_STYLE, TEXTFIELD_STYLE } from '../../constants/constants'
import UnevenQuantitiesModal from '../broker_transactions/UnevenQuantitiesModal'
import clsx from 'clsx'
import { t } from 'i18next'

const UpdateBrokerTransaction = () => {
    const fallbackPrevLocation = '/broker-transactions'

    const { config, configFile, currencySymbol, pusher, logisticsTypes } = useStateContext()
    const params = useParams()
    const { user } = useAuth({ middleware: 'guest' })
    const navigate = useNavigate()
    const location = useLocation();

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    /* state */
    const [order, setOrder] = useState([])
    const [salesOrderId, setSalesOrderId] = useState('')
    const [purchaseOrderId, setPurchaseOrderId] = useState('')

    const [shipToLocations, setShipToLocations] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])

    const [salesOrderItems, setSalesOrderItems] = useState([])
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [openSalesOrderItem, setOpenSalesOrderItem] = useState(false)
    const [openPurchaseOrderItem, setOpenPurchaseOrderItem] = useState(false)

    const [disabled, setDisabled] = useState(true)
    const [disabledFields, setDisabledFields] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [value, setValue] = useState("1")
    const [blocked, setBlocked] = useState(false)
    const [driverDocs, setDriverDocs] = useState([])
    const [driverBolDoc, setDriverBolDoc] = useState(null)
    const [hasDocs, setHasDocs] = useState(false)
    const [uploadedBillOfLadingDoc, setUploadedBillOfLadingDoc] = useState({})
    const [uploadedUnloadSheetDoc, setUploadedUnloadSheetDoc] = useState(null)
    const [totalPricePurchase, setTotalPricePurchase] = useState(0)
    const [totalQtyPurchase, setTotalQtyPurchase] = useState(0)
    const [type, setType] = useState('shipment')

    const [openCommentHistory, setOpenCommentHistory] = useState(false)
    const [openEmailDrafts, setOpenEmailDrafts] = useState(false)
    const [openUnevenQuantitiesModal, setOpenUnevenQuantitiesModal] = useState(false)
    const [emailDrafts, setEmailDrafts] = useState({})
    const [emailsSent, setEmailsSent] = useState(false)

    /* form */
    const [logisticsTypeId, setLogisticsTypeId] = useState('')
    const [logisticsTypeKey, setLogisticsTypeKey] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [isCustomerDeleted, setIsCustomerDeleted] = useState(false)
    const [vendorId, setVendor] = useState('')
    const [tplVendorId, setTplVendorId] = useState('')
    const [tplVendorName, setTplVendorName] = useState('')
    const [deliveryCost, setDeliveryCost] = useState('')
    const [isVendorDeleted, setIsVendorDeleted] = useState(false)
    const [shipToAddressId, setShipToAddressId] = useState('')
    const [purchaseAddressId, setPurchaseAddressId] = useState('')

    const [customerName, setCustomerName] = useState('')
    const [vendorName, setVendorName] = useState('')

    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')

    const [addressVendor, setAddressVendor] = useState('')
    const [zipVendor, setZipVendor] = useState('')
    const [cityVendor, setCityVendor] = useState('')
    const [stateVendor, setStateVendor] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')

    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs().endOf('day'))
    const [deliveryTime, setDeliveryTime] = useState('')
    const [shippedDate, setShippedDate] = useState(dayjs())
    const [customerPoNo, setCustomerPoNo] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [bolNo, setBolNo] = useState('')
    const [statusId, setStatusId] = useState('')
    const [prevStatusId, setPrevStatusId] = useState('')
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])
    const [file, setFile] = useState('')
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingEmailDrafts, setIsLoadingEmailDrafts] = useState(false)

    const [emailAddresses, setEmailAddresses] = useState({
        customer: '',
        supplier: '',
        tpl_company: ''
    });

    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs().endOf('minute'))
    const [pickupAppointmentTime, setPickupAppointmentTime] = useState(dayjs().endOf('minute'))

    useEffect(() => {
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
        const orderDocumentChannelUpload = pusher.subscribe(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`)
        const channelCommentCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-broker`)

        channelupdate.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getBrokerTransaction(data.id, true)
            }
        })
        channeldelete.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/broker-transactions', {
                    replace: true
                })
            }
        })
        orderDocumentChannelUpload.bind(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`, data => {
            if(data.is_deleted) {
                setOrderDocuments((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setUploadedBillOfLadingDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
                setUploadedUnloadSheetDoc((prev) => {
                    return prev?.id === data.id ? null : prev
                })
                setUploadedBillOfLadingDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
                setUploadedUnloadSheetDoc((prev) => {
                    return prev?.id === data.id ? null : prev
                })
            } else {
                getDocs()
                getDriverDocs()
                getUploadedBillOfLadingDoc()
            }
        })

        channelCommentCreated.bind(`${localStorage.getItem('client_id')}-comment-created-event`, data => {
            getComments()
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-broker-${params.id}-document-event`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-comment-created-id-${params.id}-type-broker`)
        })
    }, [])

    useEffect(() => {
        if(isValid(purchaseOrderId)) {
            const orderDocumentChannelUpload = pusher.subscribe(`${localStorage.getItem('client_id')}-purchase-${purchaseOrderId}-document-event`)

            orderDocumentChannelUpload.bind(`${localStorage.getItem('client_id')}-purchase-${purchaseOrderId}-document-event`, data => {
                if(data.is_deleted) {
                    setUploadedUnloadSheetDoc((prev) => {
                        return prev?.id === data.id ? {} : prev
                    })
                } else {
                    getUploadedUnloadSheetDoc(purchaseOrderId)
                }
            })
        }
    }, [purchaseOrderId])

    useEffect(() => {
        if(areAllValid(customerId, vendorId)) {
            const channelCustomerDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            const channelVendorDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)

            channelCustomerDeleted.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
                if(Number(data?.id) == Number(customerId)) {
                    setIsCustomerDeleted(true)
                }
            })

            channelVendorDeleted.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
                if(Number(data?.id) == Number(vendorId)) {
                    setIsVendorDeleted(true)
                }
            })
        }

        return (() => {
            if(areAllValid(customerId, vendorId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            }
        })
    }, [customerId, vendorId])

    useEffect(() => {
        if(areAllValid(shipToAddressId, purchaseAddressId)) {
            const channelShipLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
            const channelPurchaseLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)

            channelShipLocationUpdated.bind(`${localStorage.getItem('client_id')}-shipaddress-updated-event`, data => {
                if(Number(data?.id) == Number(shipToAddressId)) {
                    getShipToLocation(data?.id)
                }
            })

            channelPurchaseLocationUpdated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(data?.id) == Number(purchaseAddressId)) {
                    getPurchaseLocation(data?.id)
                }
            })
        }

        return (() => {
            if(areAllValid(shipToAddressId, purchaseAddressId)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            }
        })
    }, [shipToAddressId, purchaseAddressId])

    /* documents */
    const [orderDocuments, setOrderDocuments] = useState([])

    useEffect(() => {
        getBrokerTransaction(params.id)
    }, [params.id])

    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])


    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);


    const getBrokerTransaction = async (id, dueToPusherEvent = false) => {
        setIsLoading(true)
        await axios
            .get(`/api/broker-transactions/${id}?with=logisticsType`, config)
            .then(res => {
                const order = res.data
                setType(order?.type)
                setLogisticsTypeId(order?.logistics_type?.id)
                setLogisticsTypeKey(order?.logistics_type?.key)
                setStatusId(order?.bt_status_id)
                setPrevStatusId(order?.prev_status_id)
                setCustomerId(order?.sales_order?.customer_id)
                setCustomerName(order?.customer?.name)
                setIsCustomerDeleted(order?.is_customer_deleted || false)
                setVendor(order?.purchase_order?.vendor_id)
                setVendorName(order?.vendor?.name)
                setIsVendorDeleted(order?.is_vendor_deleted || false)
                setTplVendorId(order?.sales_order?.tpl_vendor_id || '')
                setTplVendorName(order?.tpl_vendor?.name)
                setEmailAddresses({
                    customer: order?.customer?.email,
                    supplier: order?.vendor?.order_desk_email,
                    tpl_company: order?.tpl_vendor?.order_desk_email
                })
                setDeliveryCost(toString(order?.sales_order?.delivery_cost) || '')
                if(order?.type === 'shipment') {
                    setDropTrailerNo(order?.sales_order?.drop_trailer_no || '')
                    setPickupTrailerNo(order?.sales_order?.pickup_trailer_no || '')
                } else {
                    setDropTrailerNo(order?.purchase_order?.drop_trailer_no || '')
                    setPickupTrailerNo(order?.purchase_order?.pickup_trailer_no || '')
                }
                setBolNo(order?.purchase_order?.customer_bol_no || '')
                setCustomerPoNo(order?.sales_order?.customer_po_no || '')
                setRequestedDeliveryDate(dayjs(order?.requested_delivery_date) || dayjs())
                setDeliveryTime(order?.delivery_time || '')
                setRequestedDeliveryTime(order?.requested_delivery_time ? dayjs(order?.requested_delivery_time) : dayjs().endOf('minute'))
                setPickupAppointmentTime(order?.pickup_appointment_time ? dayjs(order?.pickup_appointment_time) : dayjs().endOf('minute'))
                setShippedDate(dayjs(order?.sales_order?.shipment_date) || dayjs())
                setSalesOrderItems(order?.sales_order?.items || [])
                setPurchaseOrderItems(order?.purchase_order?.items || [])
                if (!dueToPusherEvent) {
                    setComments('')
                }
                setCommentHistory(order?.comment_history)
                setEmailsSent(order?.emails_sent)
                setSalesOrderId(order?.sales_order?.id)
                setPurchaseOrderId(order?.purchase_order?.id)
                setDriverBolDoc(order?.type === 'shipment' ? order?.sales_order?.uploaded_driver_bol : order?.purchase_order?.uploaded_driver_bol)
                setOrder(order)

                return getDriverDocs(order?.sales_order?.id)
            })
            .then(() => {
                if(
                    Object.keys(emailDrafts).length === 0
                    || dueToPusherEvent
                    /* && !order?.emails_sent */
                    /* && order?.bt_status_id > 1 */
                ) {
                    return getEmailDrafts()
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* UseEffect */
    useEffect(() => {
        getDocs()
        getUploadedBillOfLadingDoc()
    }, [])

    /* useState(() => {
        if(isValid(salesOrderId)) {
            getDriverDocs(salesOrderId)
        }
    }, [salesOrderId]) */

    useEffect(() => {
        if(isValid(purchaseOrderId)) {
            getUploadedUnloadSheetDoc(purchaseOrderId)
        }
    }, [purchaseOrderId])

    useEffect(() => {
        if (
            (statusId === 1 || statusId === 3)
            && (
                ['master_admin', 'office_manager'].includes(user?.role)
                || userHasPermissions(['daily-board-update'])
            )
        ) {
            setDisabledFields(false)
        } else {
            setDisabledFields(true)
        }
    }, [statusId])



    useEffect(() => {
        loadShipToLocation()
    }, [shipToLocations])


    useEffect(() => {
        loadPurchaseLocation()
    }, [purchaseLocations])

    useEffect(() => {
        if (isValid(customerId)) {
            getShipToLocations(customerId)
        }
    }, [customerId])

    useEffect(() => {
        if (isValid(vendorId)) {
            getPurchaseLocations(vendorId)
        }
    }, [vendorId])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [salesOrderItems, params])

    useEffect(() => {
        handleTotalPricePurchase()
        handleTotalQtyPurchase()
    }, [purchaseOrderItems, params])

    useEffect(() => {
        const items = salesOrderItems.filter((item) => item.qty_ordered !== "")

        if (items) {
            const sum = items.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            if (sum > 0) {
                setBlocked(false)
            }
            else setBlocked(true)
        }

    }, [salesOrderItems])

    useEffect(() => {
        if(
            includes(['tpl'], logisticsTypeKey)
            && (dayjs(pickupAppointmentTime).isAfter(dayjs(requestedDeliveryTime)))
        ) {
            setRequestedDeliveryTime(dayjs(pickupAppointmentTime))
        }
    }, [requestedDeliveryTime, pickupAppointmentTime, logisticsTypeKey])

    /* Handlers */

    const constructPodUrl = ({
        stream = 'view',
        party = 'supplier'
    }) => {
        return process.env.REACT_APP_BACKEND_URL + `/api/broker-transactions/${params.id}/proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleTotalPrice = () => {
        const sum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        //proci kroz sales iteme i vidjeti ima li koji qty == "" ako ima skip
        const hasShippedQtys = salesOrderItems.some((item) => Number(item.qty_shipped) > 0)
        if(hasShippedQtys) {
            const shippedQtySum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_shipped), 0);
            setTotalQty(shippedQtySum)
        } else {
            const item = salesOrderItems.filter((item) => item.qty_ordered !== "")
            if (item) {
                const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
                setTotalQty(sum)
            } else {
                setTotalQty(0)
            }
        }
    }

    const handleTotalPricePurchase = () => {
        const sum = purchaseOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPricePurchase(sum);
    }

    const handleTotalQtyPurchase = () => {
        const item = purchaseOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQtyPurchase(sum)
        } else {
            setTotalQtyPurchase(0)
        }
    }


    const handleCustomerPoNo = (e) => {
        const limit = 50
        setCustomerPoNo(e.target.value.slice(0, limit))
    }

    const loadShipToLocation = () => {
        const item = shipToLocations?.find(item => item.id === shipToAddressId)
        setAddress(item?.address)
        setZip(item?.zip)
        setCity(item?.city)
        setState(item?.state)
        setDeliveryInstructions(item?.delivery_instructions)
    }

    const loadPurchaseLocation = () => {
        const item = purchaseLocations?.find(item => item.id === purchaseAddressId)
        setAddressVendor(item?.address)
        setZipVendor(item?.zip)
        setCityVendor(item?.city)
        setStateVendor(item?.state)
        setPickupInstructions(item?.pickup_instructions)
    }

    const handleShipToLocation = (e) => {
        setShipToAddressId(e.target.value)
        const item = shipToLocations.find(item => item.id === e.target.value)
        setAddress(item.address)
        setZip(item.zip)
        setCity(item.city)
        setState(item.state)
        setDeliveryInstructions(item?.delivery_instructions)
    }

    const handlePurchaseLocation = (e) => {
        setPurchaseAddressId(e.target.value)
        const item = purchaseLocations.find(item => item.id === e.target.value)
        setAddressVendor(item.address)
        setZipVendor(item.zip)
        setCityVendor(item.city)
        setStateVendor(item.state)
        setPickupInstructions(item?.pickup_instructions)
    }


    const handleOpenSalesOrderItem = () => {
        setDisabled(true)
        setOpenSalesOrderItem(!openSalesOrderItem)
    }

    const handleOpenPurchaseOrderItem = () => {
        setDisabled(true)
        setOpenPurchaseOrderItem(!openPurchaseOrderItem)
    }

    const handleUploadClick = () => {
        inputRef.current?.click()
    }


    const getDocs = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=order_document`, config)
            .then(res => {
                const files = res.data
                setOrderDocuments(files)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDriverDocs = async (orderId) => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=drivers_document`, config)
            .then(res => {
                const files = res.data
                setDriverDocs(files)
                if (files.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedBillOfLadingDoc = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=broker&document_type=proof_of_delivery`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedBillOfLadingDoc({})
                } else {
                    setUploadedBillOfLadingDoc(data[0])
                }
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedUnloadSheetDoc = async (poid) => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${poid}&order_type=purchase&document_type=unload_sheet`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedUnloadSheetDoc(null)
                } else {
                    setUploadedUnloadSheetDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDocs = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_docs') + name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        setIsLoading(true)
        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);

        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('order_id', params.id)
        formData.append('order_type', 'broker')
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                inputRef.current.value = ""
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses/${id}`, config)
            .then((res) => {
                const shipToLocation = res.data
                setShipToLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, shipToLocation]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: shipToLocation } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const shipToLocations = res.data
                setShipToLocations(shipToLocations)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
        setShipToAddressId(order?.ship_address?.id)
    }

    const getPurchaseLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses/${id}`, config)
            .then((res) => {
                const data = res.data
                setPurchaseLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, data]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: data } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${id}`, config)
            .then(res => {
                const data = res.data
                setPurchaseLocations(data)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
        setPurchaseAddressId(order?.purchase_address?.id)
    }

    const reopenBrokerTransaction = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}

        formData['type'] = type
        formData['customer_id'] = customerId
        formData['vendor_id'] = vendorId
        formData['ship_address_id'] = shipToAddressId
        formData['purchase_address_id'] = purchaseAddressId
        formData['logistics_type_id'] = logisticsTypeId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['customer_bol_no'] = bolNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['delivery_time'] = deliveryTime
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['sales_order_items'] = salesOrderItems
        formData['purchase_order_items'] = purchaseOrderItems
        formData['comments'] = comments
        formData['site_id'] = localStorage.getItem('site')
        formData['bt_status_id'] = 3
        formData['prev_status_id'] = statusId

        if(deliveryTime === 'appointment') {
            switch(logisticsTypeKey) {
                case "delivery":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "pick_up":
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "tpl":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                default:
            }
        }

        if(logisticsTypeKey === 'tpl') {
            formData['tpl_vendor_id'] = tplVendorId
            formData['delivery_cost'] = deliveryCost
        }

        await axios
            .put(`/api/broker-transactions/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setStatusId(3)
                setDisabledFields(false)
                setComments('')
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const releaseBrokerTransaction = async (e) => {
        e.preventDefault()

        const salesOrderItemsOrdered = salesOrderItems.reduce((accumulator, current) => accumulator + toNumber(current.qty_ordered), 0)
        const purchaseOrderItemsOrdered = purchaseOrderItems.reduce((accumulator, current) => accumulator + toNumber(current.qty_ordered), 0)
        if(salesOrderItemsOrdered !== purchaseOrderItemsOrdered) {
            console.log(`Sales Items Ordered: ${salesOrderItemsOrdered}`)
            console.log(`Purchase Items Ordered: ${purchaseOrderItemsOrdered}`)
            /* setOpenUnevenQuantitiesModal(true) */
            Swal.fire({
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                text: t('ordered_quantities_not_equal_warning__alt')
            })
            return
        }

        setIsLoading(true)

        const formData = {}

        formData['type'] = type
        formData['customer_id'] = customerId
        formData['vendor_id'] = vendorId
        formData['ship_address_id'] = shipToAddressId
        formData['purchase_address_id'] = purchaseAddressId
        formData['logistics_type_id'] = logisticsTypeId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['customer_bol_no'] = bolNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['delivery_time'] = deliveryTime
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['sales_order_items'] = salesOrderItems
        formData['purchase_order_items'] = purchaseOrderItems
        formData['comments'] = comments
        formData['site_id'] = localStorage.getItem('site')
        formData['bt_status_id'] = isValid(prevStatusId) ? prevStatusId : 2
        formData['prev_status_id'] = null

        if(deliveryTime === 'appointment') {
            switch(logisticsTypeKey) {
                case "delivery":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "pick_up":
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "tpl":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                default:
            }
        }

        if(logisticsTypeKey === 'tpl') {
            formData['tpl_vendor_id'] = tplVendorId
            formData['delivery_cost'] = deliveryCost
        }

        await axios
            .put(`/api/broker-transactions/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setDisabledFields(true)
                setStatusId(2)
                setComments('')
                if(!emailsSent) {
                    setOpenEmailDrafts(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getEmailDrafts = async () => {
        setIsLoadingEmailDrafts(true)

        await axios
            .get(`/api/broker-transactions/${params.id}/preview-emails`, config)
            .then(({ data }) => {
                setEmailDrafts(data)
            })
            .finally(() => {
                setIsLoadingEmailDrafts(false)
            })
    }

    const getComments = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/comments?order_id=${params.id}&order_type=broker`)
            .then(res => {
                const data = res.data
                setCommentHistory(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateBrokerTransaction = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['type'] = type
        formData['customer_id'] = customerId
        formData['vendor_id'] = vendorId
        formData['ship_address_id'] = shipToAddressId
        formData['purchase_address_id'] = purchaseAddressId
        formData['logistics_type_id'] = logisticsTypeId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['customer_bol_no'] = bolNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['delivery_time'] = deliveryTime
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['sales_order_items'] = salesOrderItems
        formData['purchase_order_items'] = purchaseOrderItems
        formData['comments'] = comments
        formData['site_id'] = localStorage.getItem('site')

        if(deliveryTime === 'appointment') {
            switch(logisticsTypeKey) {
                case "delivery":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "pick_up":
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "tpl":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                default:
            }
        }

        if(logisticsTypeKey === 'tpl') {
            formData['tpl_vendor_id'] = tplVendorId
            formData['delivery_cost'] = deliveryCost
        }

        await axios
            .put(`/api/broker-transactions/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
                /* getBrokerTransaction(params.id) */
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const shipBrokerTransaction = async (e) => {
        e.preventDefault()

        if(!emailsSent) {
            const isConfirm = await Swal.fire({
                text: t('emails_not_sent_warning'),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: t('yes'),
                cancelButtonText: t('negatory')
            }).then((result) => {
                return result.isConfirmed
            })

            if (!isConfirm) {
                return
            }
        }

        setIsLoading(true)

        const formData = {}

        await axios
            .put(`/api/broker-transactions/${params.id}/ship`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getBrokerTransaction(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const completeBrokerTransactionAsDriver = async (e) => {
        e.preventDefault()

        setIsLoading(true)

        const formData = {}

        await axios
            .put(`/api/broker-transactions/${params.id}/complete-as-driver`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getBrokerTransaction(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const confirmBrokerTransaction = async (e) => {
        e.preventDefault()

        const isConfirm = await Swal.fire({
            text: t('confirm_order_confirmation'),
            icon: 'warning',
            customClass: 'warning2',
            iconColor: '#FEB806',
            reverseButtons: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: t('yes'),
            cancelButtonText: t('negatory')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }

        setIsLoading(true)

        await axios
            .put(`/api/broker-transactions/${params.id}/confirm?action=shortcut`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success?.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const completeBrokerTransaction = async (e) => {
        e.preventDefault()

        if(!emailsSent) {
            const isConfirm = await Swal.fire({
                text: t('emails_not_sent_warning'),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: t('yes'),
                cancelButtonText: t('negatory')
            }).then((result) => {
                return result.isConfirmed
            })

            if (!isConfirm) {
                return
            }
        }

        setIsLoading(true)

        await axios
            .put(`/api/broker-transactions/${params.id}/complete`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getBrokerTransaction(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const clickGenerateInvoiceBillHandler = async (e) => {
        e.preventDefault()

        if(isCustomerDeleted || isVendorDeleted) {
            Swal.fire({
                text: t('x_or_y_have_been_deleted_warning', {
                    x: t('customer'),
                    y: t('vendor')
                }),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showDenyButton: true,
                showCloseButton: false,
                confirmButtonText: t('yes'),
                denyButtonText: t('negatory'),
                allowOutsideClick: false
            }).then((result) => {
                if(result.isConfirmed) {
                    generateInvoiceBill()
                }
            })
        } else {
            generateInvoiceBill()
        }
    }

    const generateInvoiceBill = async (e) => {
        setIsLoading(true)

        await axios
            .put(`/api/broker-transactions/${params.id}/generate-invoice-bill`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getBrokerTransaction(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const salesOrderItemsCopy = [...salesOrderItems]
        const item = salesOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setSalesOrderItems(salesOrderItemsCopy)
    }

    const handleUpdateItemPurchase = (id, quantity, unit_price) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...salesOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(qts)


        setSalesOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateItemQTYPurchase = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(qts)


        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleCloseView = () => {
        if(location.key !== 'default') {
            navigate(-1)
        } else {
            navigate(!isNil(location.state?.prevPathname) ? location.state.prevPathname : fallbackPrevLocation)
        }
    }

    {/* COLUMNS */}

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedSalesCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
             flex: 1,
             minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipSalesCell params={params} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedSalesCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
             flex: 0.7,
             minWidth: 75,
            renderCell: (params) => <UnitPriceSalesCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalSalesCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.7,
            minWidth: 75,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} />
        },
    ]

    const columns2 = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedSalesCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipSalesCell params={params} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedSalesCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceSalesCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalSalesCell params={params} currencySymbol={currencySymbol} />
        }
    ]

    const columnsPurchase = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75,
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedPurchaseCell params={params} disabled={disabledFields} handler={handleUpdateItemPurchase} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceivePurchaseCell params={params} disabled={disabledFields} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedPurchaseCell params={params} />
        },
        /* {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountPurchaseCell params={params} disabled={disabledFields} />
        }, */
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPricePurchaseCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalPurchaseCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            cellClassName: 'padding-0',
            renderCell: (params) => <ActionsPurchase params={params} salesOrderItems={purchaseOrderItems} setSalesOrderItems={setPurchaseOrderItems} />
        }
    ]

    const columnsPurchase2 = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedPurchaseCell params={params} disabled={disabledFields} handler={handleUpdateItemPurchase} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceivePurchaseCell params={params} disabled={disabledFields} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedPurchaseCell params={params} />
        },
        /* {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountPurchaseCell params={params} disabled={disabledFields} />
        }, */
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPricePurchaseCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalPurchaseCell params={params} currencySymbol={currencySymbol} />
        }
    ]

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <div className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#148c8cb3] text-[35px] mr-4'>•</span>
                            <p>
                                {
                                    (
                                        (
                                            ['master_admin', 'office_manager'].includes(user?.role)
                                            || userHasPermissions(['daily-board-update'])
                                        )
                                        && !includes(['invoiced', 'completed'], order?.bt_status_slug)
                                    )
                                    ? t('update_broker_transaction')
                                    : t('view_broker_transaction')
                                } - {order?.sales_order?.so_number} {order?.purchase_order?.po_number}
                            </p>
                        </div>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={handleCloseView}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='w-full lg:w-2/5'>

                        {/* STATUS SECTION */}
                        <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                            <div className='pb-5 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('status')}</p>
                            </div>

                            {/* status */}
                            <div className={`w-full pb-4`}>
                                <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                    {order?.bt_status_name}
                                    {/* TYPE IS shipment */}
                                    {
                                        type === 'shipment' && (
                                            <>
                                                {
                                                    user.role === 'master_admin'
                                                        ? includes(['released'], order.bt_status_slug)
                                                            ? <Tooltip disableInteractive title={t('changeToShiped')}><button onClick={e => shipBrokerTransaction(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-check"></i></button></Tooltip>
                                                            : null
                                                        : null
                                                }
                                            </>
                                        )
                                    }
                                    {/* TYPE IS collection */}
                                    {
                                        type === 'collection' && (
                                            <>
                                                {
                                                    user.role === 'master_admin'
                                                        ? includes(['released'], order.bt_status_slug)
                                                            ? <Tooltip disableInteractive title={t('changeToCompletedByDriver')}><button onClick={e => completeBrokerTransactionAsDriver(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-check"></i></button></Tooltip>
                                                            : null
                                                        : null
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        includes(['master_admin', 'office_manager'], user?.role)
                                            ? includes(['shipped', 'completed_by_driver', 'on_site'], order.bt_status_slug)
                                                ? <Tooltip disableInteractive title={t('changeToConfirmed')}><button onClick={e => confirmBrokerTransaction(e)} className='text-[#336195] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-check"></i></button></Tooltip>
                                                : null
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        {/* LOGISTICS SECTION */}
                        <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                            <div className='pb-5 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('logistics')} *</p>
                            </div>

                            <div className='w-full pb-4'>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    disabled
                                    id="logistics-type-autocomplete"
                                    value={{id: logisticsTypeId, label: t(logisticsTypeKey)}}
                                    options={logisticsTypes.map((type) => ({id: type.id, label: t(type.key)}))}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{
                                        ...AUTOCOMPLETE_STYLE,
                                        '.MuiOutlinedInput-input': {
                                            padding: '0 !important'
                                        }
                                    }}
                                    /* onChange={(a, b) => setLogisticsTypeId(b?.id || '')} */
                                    renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                />
                            </div>
                        </div>

                        <div
                            className={clsx(
                                'flex flex-col', {
                                    'flex-col-reverse': type === 'collection'
                                }
                            )}
                        >
                            {/* CUSTOMER SECTION */}
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{type === 'collection' ? t('service_provider') : t('customer')}</p>
                                </div>
                                {/* customer */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{type === 'collection' ? t('service_provider') : t('customer')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        disabled
                                        name="customer"
                                        value={customerName}
                                    />
                                </div>

                                {/* Ship to code */}
                                {(
                                    (logisticsTypeKey !== 'pick_up' && type === 'shipment')
                                    || type === 'collection'
                                ) ? (
                                    <>
                                        <div className='w-full pb-4'>
                                            <Stack
                                                direction='row'
                                                gap={1}
                                                alignItems='baseline'
                                                justifyContent='space-between'
                                            >
                                                <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                                {(shipToAddressId && isValid(deliveryInstructions)) ? (
                                                    <Tooltip
                                                        title={
                                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                                {deliveryInstructions}
                                                            </div>
                                                        }
                                                    >
                                                        <Stack
                                                            direction='row'
                                                            gap={1}
                                                            alignItems='center'
                                                            className='text-[#718096]'
                                                        >
                                                            <MenuBookIcon fontSize='18px'/>
                                                            <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                                        </Stack>
                                                    </Tooltip>
                                                ) : null}
                                            </Stack>

                                            <div className='w-full'>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Select
                                                        sx={SELECT_STYLE}
                                                        value={shipToAddressId}
                                                        onChange={(e) => { handleShipToLocation(e) }}
                                                        disabled
                                                    >
                                                        {shipToLocations?.map((item, index) => <MenuItem key={`stls-${index}`} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        {/* Address */}
                                        {
                                            shipToAddressId ?
                                                <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                                    <div className='w-full'>{address}</div>
                                                    {shipToAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                                </div>
                                                :
                                                null
                                        }
                                    </>
                                ) : (
                                    <div className='w-full p-4 address mb-4'>
                                        <div className='w-full text-[#00000061]'>{t('pick_up')}</div>
                                    </div>
                                )}
                                {/* Customer PO No. */}
                                <div className={`w-full`}>
                                    <p className="text-[14px] text-[#718096]">{t('customer_po_no')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        disabled={disabledFields}
                                        className='w-full'
                                        name="customer_po_no"
                                        value={customerPoNo}
                                        onChange={(e) => { handleCustomerPoNo(e) }}
                                    />
                                    <p className='text-xs w-full italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                                </div>
                            </div>

                            {/* SUPPLIER SECTION */}
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{type === 'collection' ? t('supplier') : t('vendor')}</p>
                                </div>
                                {/* vendor */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('supplier')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        disabled
                                        name="supplier"
                                        value={vendorName}
                                    />
                                </div>

                                {/* Purchase Location */}
                                <div className='w-full pb-4'>
                                    <Stack
                                        direction='row'
                                        gap={1}
                                        alignItems='baseline'
                                        justifyContent='space-between'
                                    >
                                        <p className="text-[14px] text-[#718096]">{t('purchaseLocation')} *</p>
                                        {(purchaseAddressId && isValid(pickupInstructions)) ? (
                                            <Tooltip
                                                title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                        {pickupInstructions}
                                                    </div>
                                                }
                                            >
                                                <Stack
                                                    direction='row'
                                                    gap={1}
                                                    alignItems='center'
                                                    className='text-[#718096]'
                                                >
                                                    <MenuBookIcon fontSize='18px'/>
                                                    <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('pickup_instructions')}</span>
                                                </Stack>
                                            </Tooltip>
                                        ) : null}
                                    </Stack>

                                    <div className='w-full'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                value={purchaseAddressId}
                                                onChange={(e) => { handlePurchaseLocation(e) }}
                                                disabled
                                                sx={SELECT_STYLE}
                                            >
                                                {purchaseLocations?.map((item, index) => <MenuItem key={`pls-${index}`} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                {/* Address */}
                                {
                                    purchaseAddressId ?
                                        <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                            <div className='w-full'>{addressVendor}</div>
                                            {purchaseAddressId ? <div className='w-full'>{zipVendor} {cityVendor}, {stateVendor}</div> : <div className='w-full'></div>}
                                        </div>
                                        :
                                        null
                                }


                                {/* Bol No. */}
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('bol_number')}</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        disabled={disabledFields}
                                        type="text"
                                        name="bol_no"
                                        value={bolNo} onChange={(e) => { setBolNo(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 3PL COMPANY section */}
                        {logisticsTypeKey === 'tpl' ? (
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('tpl_company')}</p>
                                </div>
                                {/* 3PL Vendor */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('tpl_vendor')} *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        disabled
                                        className='w-full'
                                        name="tpL_vendor_textfield"
                                        value={tplVendorName}
                                    />
                                </div>

                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('delivery_cost')} ({currencySymbol}) *</p>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        className='w-full'
                                        name="delivery_cost"
                                        value={deliveryCost}
                                        disabled={disabledFields}
                                        onChange={(e) => handleNumber({
                                            event: e,
                                            setter: setDeliveryCost,
                                            precision: 2
                                        })}
                                        onBlur={e => handleZero({
                                            event: e,
                                            setter: setDeliveryCost
                                        })}
                                        onFocus={e => deleteZero({
                                            event: e,
                                            setter: setDeliveryCost,
                                            value: deliveryCost
                                        })}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {/* GENERAL INFORMATION SECTION */}
                        <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view '>
                            <div className='pb-5 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                            </div>
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    disabled={disabledFields}
                                    type="text"
                                    name="drop_trailer_no"
                                    value={dropTrailerNo}
                                    onChange={(e) => { setDropTrailerNo(e.target.value) }}
                                />
                            </div>
                            {/* Pickup Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    disabled={disabledFields}
                                    type="text"
                                    name="pickup_trailer_no"
                                    value={pickupTrailerNo}
                                    onChange={(e) => { setPickupTrailerNo(e.target.value) }}
                                />
                            </div>

                            {/* Requested Delivery|Collection Date */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{type === 'collection' ? t('requested_collection_date') : t('requested_delivery_date')} *</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={MOBILE_DATE_PICKER_STYLE}
                                            disabled={disabledFields}
                                            format="YYYY-MM-DD"
                                            value={requestedDeliveryDate}
                                            onChange={(newValue) => setRequestedDeliveryDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            {/* Delivery Time */}
                            {!(
                                type === 'collection'
                                && logisticsTypeKey === 'pick_up'
                            ) ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('delivery_time')} *</p>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            value={deliveryTime}
                                            onChange={(e) => { setDeliveryTime(e.target.value) }}
                                            disabled={disabledFields}
                                            sx={SELECT_STYLE}
                                        >
                                            <MenuItem value={'first_come_first_served'}>{t('first_come_first_served')}</MenuItem>
                                            <MenuItem value={'appointment'}>{t('appointment')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            ) : null}

                            {/* Appointment Time(s) */}
                            {deliveryTime === 'appointment' && includes(['delivery', 'pick_up', 'tpl'], logisticsTypeKey) ? (
                                <div className={`w-full pb-4 ${logisticsTypeKey === 'tpl' ? 'flex flex-row gap-x-1.5' : null}`}>
                                    {includes(['pick_up', 'tpl'], logisticsTypeKey) && (
                                        <div className={`${logisticsTypeKey === 'tpl' ? 'w-1/2' : 'w-full'}`}>
                                            <p className="text-[14px] text-[#718096]">{t('pickup_appointment_time')} *</p>
                                            <div className="w-full">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker
                                                            sx={MOBILE_TIME_PICKER_STYLE}
                                                            disabled={disabledFields}
                                                            value={pickupAppointmentTime}
                                                            onChange={(newValue) => setPickupAppointmentTime(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    )}
                                    {includes(['delivery', 'tpl'], logisticsTypeKey) && (
                                       <div className={`${logisticsTypeKey === 'tpl' ? 'w-1/2' : 'w-full'}`}>
                                            <p className="text-[14px] text-[#718096]">{t('delivery_appointment_time')} *</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                        <MobileTimePicker
                                                            minTime={includes(['tpl'], logisticsTypeKey) ? pickupAppointmentTime : null}
                                                            sx={MOBILE_TIME_PICKER_STYLE}
                                                            disabled={disabledFields}
                                                            value={requestedDeliveryTime}
                                                            onChange={(newValue) => setRequestedDeliveryTime(newValue)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : null}

                            {/* Shipment Date */}
                            {!localStorage.getItem('client_id').includes('pallet-book') && (
                                <div className='w-full'>
                                    <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                sx={MOBILE_DATE_PICKER_STYLE}
                                                disabled={disabledFields || localStorage.getItem('client_id').includes('pallet-book')}
                                                format="YYYY-MM-DD"
                                                value={shippedDate}
                                                onChange={(newValue) => setShippedDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='w-full lg:w-3/5'>
                        <div
                            className={clsx(
                                'flex flex-col', {
                                    'flex-col-reverse': type === 'collection'
                                }
                            )}
                        >
                            {/* Sales Order Items */}
                            <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                                </div>
                                <div className='flex justify-center w-full gap-2'>
                                    <p className='underline font-bold'>{t('your_margin')}:</p><p className={totalPrice - (totalPricePurchase + Number(deliveryCost)) <= 0 ? `text-red-600` : `text-green-600`}>{currencySymbol}{(totalPrice - (totalPricePurchase + Number(deliveryCost))).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        {' '} / {' '}
                                        {Math.round(((totalPrice - (totalPricePurchase + Number(deliveryCost))) / totalPrice) * 100) + '%'}
                                    </p>
                                </div>

                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addsalesOrderitems')} placement='bottom'>
                                        <div>
                                            <AddButton disabled={disabledFields} onClick={handleOpenSalesOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='border-b'>
                                    {
                                        salesOrderItems.length > 0 ?
                                            (
                                                (statusId == 1 || statusId == 3)
                                                && (
                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                    || userHasPermissions(['daily-board-update'])
                                                )
                                            ) ?
                                                <div className='flex justify-end w-full p-2'>
                                                    <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setSalesOrderItems([])}>{t('clear_all')}</button>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                    <TableShared columns={disabledFields ? columns2 : columns} items={salesOrderItems} disableColumnMenu={true} />
                                </div>
                                <div className='flex justify-end w-full p-5'>
                                    <div className='w-full lg:w-1/3'>
                                        <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                            <p>{t('totalPrice')}</p>
                                            <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                        <div className='flex justify-between text-[#333333] text-[15px]'>
                                            <p>{t('totalQty')}</p>
                                            <p className='font-[500]'>{totalQty}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Purchase Order Items */}
                            <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('purchaseOrderItems')}</p>
                                </div>
                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addpurchaseOrderItems')} placement='bottom'>
                                        <div>
                                            <AddButton disabled={disabledFields} onClick={handleOpenPurchaseOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='border-b'>
                                    {
                                        purchaseOrderItems.length > 0 ?
                                            ((statusId == 1 || statusId == 3) && ['master_admin', 'office_manager'].includes(user?.role)) ?
                                                <div className='flex justify-end w-full p-2'>
                                                    <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setPurchaseOrderItems([])}>{t('clear_all')}</button>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                    }
                                    <TableShared columns={disabledFields ? columnsPurchase2 : columnsPurchase} items={purchaseOrderItems} disableColumnMenu={true} />
                                </div>

                                <div className='flex justify-end w-full p-5'>
                                    <div className='w-full lg:w-1/3'>
                                        <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                            <p>{t('totalPrice')}</p>
                                            <p className='font-[500]'>{currencySymbol}{totalPricePurchase.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                        <div className='flex justify-between text-[#333333] text-[15px]'>
                                            <p>{t('totalQty')}</p>
                                            <p className='font-[500]'>{totalQtyPurchase}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block gap-3 lg:flex'>
                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5 flex justify-between items-start'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={commentHistory?.length ?? ''}
                                        backgroundColor='#5aaeae'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <div className='w-full p-5 pt-0'>
                                    <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        value={comments}
                                        disabled={disabledFields}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                </div>
                                <div className='w-full'>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="document tab list" variant='scrollable'>
                                                <Tab sx={{ textTransform: 'none' }} value="1" label={t('order_documents')} />
                                                <Tab sx={{ textTransform: 'none' }} value="2" label={t('bill_of_lading')} />
                                                {type === 'collection' && (
                                                    <Tab sx={{ textTransform: 'none' }} value="3" label={t('unload_sheet')} disabled={!uploadedUnloadSheetDoc} />
                                                )}
                                            </TabList>
                                        </Box>
                                        {/* ORDER DOCUMENTS */}
                                        <TabPanel value="1">
                                            <div className='w-full text-center'>
                                                <Button onClick={handleUploadClick} disabled={disabledFields} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                    <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload')}</p> </div>
                                                </Button>
                                                <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'order_document')} />
                                            </div>
                                            <>
                                                {
                                                    orderDocuments?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={`odocs-${index}`}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                <button disabled={order?.bt_status_slug === 'invoiced'} className={`text-neutral-500 ${order?.bt_status_slug === 'invoiced' ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {hasDocs ? (
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_documents')}</div>
                                                ) : (
                                                    ''
                                                )}
                                                {
                                                    driverDocs?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={`ddocs-${index}`}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                <button disabled={order?.bt_status_slug === 'invoiced'} className={`text-neutral-500 ${order?.bt_status_slug === 'invoiced' ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </TabPanel>

                                        {/* PROOFS OF DELIVERY */}
                                        <TabPanel value="2">
                                            {/* Auto-generated Bills of Lading */}
                                            {(
                                                Object.keys(uploadedBillOfLadingDoc).length <= 0
                                                && !driverBolDoc
                                            ) && (
                                                <div className='flex flex-col gap-2 pb-5'>
                                                    {/* CUSTOMER BoL */}
                                                    <div className='flex justify-between'>
                                                        <p className='text-blue-900 underline'>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'view',
                                                                    party: 'customer'
                                                                })}
                                                                target="_blank"
                                                            >
                                                                <span
                                                                    className="text-[#336195]"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {type === 'shipment' ? t('customer') : t('service_provider')} {t('bill_of_lading')}
                                                                </span>
                                                            </a>
                                                        </p>
                                                        <div className='flex items-center gap-3'>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'view',
                                                                    party: 'customer'
                                                                })}
                                                                target="_blank"
                                                                download
                                                            >
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-eye"></i></Tooltip></button>
                                                            </a>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'download',
                                                                    party: 'customer'
                                                                })}
                                                                target="_blank"
                                                                download
                                                            >
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-download"></i></Tooltip></button>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {/* SUPPLIER BoL */}
                                                    <div className='flex justify-between'>
                                                        <p className='text-blue-900 underline'>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'view',
                                                                    party: 'supplier'
                                                                })}
                                                                target="_blank"
                                                            >
                                                                <span
                                                                    className="text-[#336195]"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {t('supplier')} {t('bill_of_lading')}
                                                                </span>
                                                            </a>
                                                        </p>
                                                        <div className='flex items-center gap-3'>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'view',
                                                                    party: 'supplier'
                                                                })}
                                                                target="_blank"
                                                                download
                                                            >
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-eye"></i></Tooltip></button>
                                                            </a>
                                                            <a
                                                                href={constructPodUrl({
                                                                    stream: 'download',
                                                                    party: 'supplier'
                                                                })}
                                                                target="_blank"
                                                                download
                                                            >
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-download"></i></Tooltip></button>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {/* 3PL COMPANY BoL */}
                                                    {logisticsTypeKey === 'tpl' ? (
                                                        <div className='flex justify-between'>
                                                            <p className='text-blue-900 underline'>
                                                                <a
                                                                    href={constructPodUrl({
                                                                        stream: 'view',
                                                                        party: 'tpl'
                                                                    })}
                                                                    target="_blank"
                                                                >
                                                                    <span
                                                                        className="text-[#336195]"
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {t('tpl_company')} {t('bill_of_lading')}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                            <div className='flex items-center gap-3'>
                                                                <a
                                                                    href={constructPodUrl({
                                                                        stream: 'view',
                                                                        party: 'tpl'
                                                                    })}
                                                                    target="_blank"
                                                                    download
                                                                >
                                                                    <button className='text-neutral-500'><Tooltip disableInteractive
                                                                        title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                            className="fa-solid fa-eye"></i></Tooltip></button>
                                                                </a>
                                                                <a
                                                                    href={constructPodUrl({
                                                                        stream: 'download',
                                                                        party: 'tpl'
                                                                    })}
                                                                    target="_blank"
                                                                    download
                                                                >
                                                                    <button className='text-neutral-500'><Tooltip disableInteractive
                                                                        title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                            className="fa-solid fa-download"></i></Tooltip></button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}

                                            {/* Manually-uploaded Bills of Lading */}

                                            {Object.keys(uploadedBillOfLadingDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600'>{t('uploaded_x', { x: t('bill_of_lading') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedBillOfLadingDoc.document_url} target="_blank" download>{uploadedBillOfLadingDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedBillOfLadingDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                ) ? (
                                                                    <button className={`text-neutral-500`} onClick={e => deleteDocs(uploadedBillOfLadingDoc.id, uploadedBillOfLadingDoc.document_name)}><Tooltip disableInteractive title={t('delete_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='w-full text-center'>
                                                    <Button onClick={handleUploadClick} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('BoL') })}</p> </div>
                                                    </Button>
                                                    <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'proof_of_delivery')} />
                                                </div>
                                            )}

                                            {driverBolDoc ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_bill_of_lading')}</div>

                                                    <div className='pt-3 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3' ><a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download>{driverBolDoc?.document_name}</a></p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('download_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-download"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </TabPanel>

                                        {/* UNLOAD SHEET */}
                                        <TabPanel value="3">
                                            {uploadedUnloadSheetDoc && (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('uploaded_x', { x: t('unload_sheet') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedUnloadSheetDoc.document_url} target="_blank" download>{uploadedUnloadSheetDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedUnloadSheetDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('unload_sheet') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-3'>
                            {
                                (
                                    ['master_admin', 'office_manager'].includes(user?.role)
                                    || userHasPermissions(['daily-board-update'])
                                ) ? (
                                    <>
                                        <div className='flex gap-3'>
                                            {
                                                (
                                                    includes(['open'], order?.bt_status_slug)
                                                ) ?
                                                null
                                                : (
                                                    <button disabled={Object.keys(emailDrafts).length === 0} type="button" onClick={() => setOpenEmailDrafts(true)} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#001e67] hover:bg-[#001a5a] disabled:bg-[#B8B7BC]'>
                                                        <span className='pr-2'><i className="fa-solid fa-file-import"></i></span> {!emailsSent ? t('review_email_drafts') : t('resend_emails')}
                                                    </button>
                                                )
                                            }
                                            {
                                                includes(['invoiced', 'completed', 'shipped', 'confirmed'], order?.bt_status_slug) ?
                                                    null
                                                    :
                                                    <button type="button" disabled={disabledFields} onClick={updateBrokerTransaction} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                                        <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                                                    </button>
                                            }
                                            {
                                                (
                                                    order?.bt_status_slug !== 'reopened'
                                                    && !includes(['open', 'invoiced', 'completed', 'shipped'], order?.bt_status_slug)
                                                )
                                                    ? (
                                                        <button type="button" onClick={reopenBrokerTransaction} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#FD9B00] hover:bg-[#DB8600] disabled:bg-[#B8B7BC]'>
                                                            <span className='pr-2'><i className="fa-solid fa-file-import"></i></span> {t('reopen')}
                                                        </button>
                                                    )
                                                    : null
                                            }
                                            {
                                                includes(['open', 'reopened'], order?.bt_status_slug) ?
                                                    blocked ?
                                                        <Tooltip disableInteractive title={t('please_have_item')}>
                                                            <button type="button" disabled onClick={releaseBrokerTransaction} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                                                                <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('release')}
                                                            </button>
                                                        </Tooltip>
                                                        :
                                                        <button type="button" onClick={releaseBrokerTransaction} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                                                            <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('release')}
                                                        </button>
                                                    :
                                                    null
                                            }
                                            {
                                                order.bt_status_slug == 'confirmed' ?
                                                    !includes(['master_admin', 'office_manager'], user?.role) ?
                                                        null
                                                        :
                                                        <button type="button" onClick={completeBrokerTransaction} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#00C448] hover:bg-[#019738] disabled:bg-[#B8B7BC]'>
                                                            <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('complete')}
                                                        </button>
                                                    :
                                                    null
                                            }
                                            {
                                                order?.bt_status_slug == 'completed' ?
                                                    user?.role !== 'master_admin' ?
                                                        null
                                                        :
                                                        <button type="button" title={t('generate_invoice_bill_in_quickbooks')} onClick={clickGenerateInvoiceBillHandler} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#007EA7] hover:bg-[#01546F] disabled:bg-[#B8B7BC]'>
                                                            <span className='pr-2'><i className="fa-regular fa-file-lines"></i></span> {t('generate_invoice_bill')}
                                                        </button>
                                                    :
                                                    null
                                            }


                                        </div>

                                    </>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                </div>
            </Box>
            <AddSalesOrderItem open={openSalesOrderItem} handleOpen={handleOpenSalesOrderItem} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} disabled={disabled} setDisabled={setDisabled} />
            <AddPurchaseOrderItem open={openPurchaseOrderItem} handleOpen={handleOpenPurchaseOrderItem} purchaseOrderItems={purchaseOrderItems} setPurchaseOrderItems={setPurchaseOrderItems} disabled={disabled} setDisabled={setDisabled} />


            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
            <BrokerTransactionEmailDraftsModal
                open={openEmailDrafts}
                setOpen={setOpenEmailDrafts}
                setIsLoading={setIsLoading}
                isLoadingEmailDrafts={isLoadingEmailDrafts}
                setEmailsSent={setEmailsSent}
                brokerTransactionId={params.id}
                drafts={emailDrafts}
                addresses={emailAddresses}
                logisticsType={logisticsTypeKey}
            />

            <UnevenQuantitiesModal
                open={openUnevenQuantitiesModal}
                setOpen={setOpenUnevenQuantitiesModal}
                continueHandler={releaseBrokerTransaction}
                t={t}
            />
        </>
    )
}

export default UpdateBrokerTransaction

const maxQtyDigits = 5

{/* SALES ORDER ITEM cells */}

const QtyOrderedSalesCell = ({ params, handler, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_ship) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_ship
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            disabled={disabled}
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToShipSalesCell = ({ params, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_ship])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        text: t('warningSalesOrder'),
                        icon: 'warning',
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000',
                        reverseButtons: true,
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value,
                    )
                }
            }
        }
    }

    return (
        <TextField
            disabled={disabled}
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-ship-sales"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyShippedSalesCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_shipped])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-shipped-sales"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            })}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const UnitPriceSalesCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalSalesCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const Actions = ({ params, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

{/* PURCHASE ORDER ITEM cells */}

const QtyOrderedPurchaseCell = ({ params, handler, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "") {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_receive) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_receive
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            disabled={disabled}
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered-purchase"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToReceivePurchaseCell = ({ params, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_receive])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                    icon: "warning",
                    text: t('warningPurchaseOrder'),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value
                    )
                }
            }
        }
    }

    return (
        <TextField
            disabled={disabled}
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-receive-purchase"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyReceivedPurchaseCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_received])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const VendorCountPurchaseCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count-purchase"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                qty
            })}
        />
    )
}

const UnitPricePurchaseCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalPurchaseCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const ActionsPurchase = ({ params, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
